import { useMemo, useState } from "react";
import { SortationCenterError } from "@deliverr/sortation-client";
import { SoundFx, createSuccessNotification, log, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationPrint } from "sortation/base/useSortationPrint";
import { useSortationModal } from "sortation/components/SortationModal";
import { OutboundModal } from "sortation/pages/Outbound/modals";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

export const useShippingLabelPrint = () => {
  const { formatMessage } = useIntl();
  const { print } = useSortationPrint();
  const { playSfx, addAutoCloseNotification, resetNotifications, customResponse } = useCommonFlow();
  const { handleSortationCenterError } = useSortationCenterError();
  const { showModal, hideModal } = useSortationModal();
  const sortationId = useRecoilValue(sortationIdState);
  const sortationClient = useSortationClient();

  const [trackingCode, setTrackingCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const errorResponse = useMemo(() => customResponse(SoundFx.ERROR_FAST, "DANGER"), [customResponse]);

  const handleError = (message: string) => {
    errorResponse();
    setErrorMessage(
      formatMessage({
        id: "sortation.shippingLabelPrint.error",
        defaultMessage: message,
      })
    );
  };

  // Attempty to decode and print the label on modal confirmation
  const onModalConfirm = async (labelContents: string) => {
    const ctx = logStart({ fn: "useShippingLabelPrint.onModalConfirm", labelContents });
    try {
      // Attempt to base64 decode the label contents and print
      const zplString = atob(labelContents);
      log(ctx, "decoded base64 label zpl");
      await print(zplString);

      // Reset state for success
      setTrackingCode("");
      addAutoCloseNotification(
        createSuccessNotification(
          formatMessage({
            id: "sortation.shippingLabelPrint.success",
            defaultMessage: "Label sent to printer",
          })
        ),
        TOAST_AUTO_CLOSE
      );
    } catch (err) {
      logError(ctx, err);

      // If the error is thrown after the success toast we want to reset the toast notifications
      resetNotifications();
      handleError("Unable to print label");
    } finally {
      hideModal(OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION);
    }
  };

  const [handleReprintState, handleReprint] = useAsyncFn(async (input: string) => {
    const ctx = logStart({ fn: "useShippingLabelPrint.onPrintButtonClick", input, sortationId });

    const code = parseTrackingInput(input);

    // Ensure our state is correct prior to handling a reprint
    setTrackingCode(code);
    setErrorMessage("");
    resetNotifications();

    try {
      // Attempt to get recipient name and base64 label
      const { recipientName, labelContents } = await sortationClient.reprintShippingLabel(code, sortationId!);
      log(ctx, "retrieved recipient name and label contents");

      // Play a success sound and show a confirmation modal on success
      playSfx(SoundFx.SUCCESS);
      showModal(OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION, {
        name: recipientName,
        onConfirm: async () => await onModalConfirm(labelContents),
      });
    } catch (err) {
      logError(ctx, err);
      if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        handleSortationCenterError();
        return;
      }

      handleError("Invalid tracking code");
    }
  });

  return {
    trackingCode,
    setTrackingCode,
    errorMessage,
    handleReprint,
    handleReprintState,
  };
};
