import { DefaultTheme, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { DivertLaneTitle } from "./components/DivertLaneTitle";
import { useDivertLane } from "./useDivertLane";

const Center = styled.div<Record<string, unknown>, DefaultTheme>`
  margin-top: ${({ theme }) => theme.spacing.S2};
  text-align: center;
`;

export const DivertLane = () => {
  const { validDivertLane, scanConfig } = useDivertLane();

  return (
    <div>
      <DivertLaneTitle value={validDivertLane} />
      <SimpleTextInputCard
        noTopBorderRadius
        title={scanConfig.title}
        label={scanConfig.label}
        value={scanConfig.value ?? ""}
        onSubmit={scanConfig.onSubmit}
        onChange={scanConfig.onChange}
        disabled={scanConfig.disabled}
        debounceSubsequent
      />
      {validDivertLane && (
        <Center>
          <Text appearance="INFO">
            <FormattedMessage
              id="sortation.scanDivertLane.scanAnotherDivertLane"
              defaultMessage="or scan another divert lane"
            />
          </Text>
        </Center>
      )}
    </div>
  );
};
