import React from "react";

export const SearchIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
      fill="#D9E5FF"
      fillOpacity="0.3"
    />
    <path
      d="M30.6667 40.0002C25.5121 40.0002 21.3334 35.8215 21.3334 30.6668C21.3334 25.5122 25.5121 21.3335 30.6667 21.3335C35.8214 21.3335 40 25.5122 40 30.6668C39.9942 35.8191 35.819 39.9944 30.6667 40.0002Z"
      fill="#D9E5FF"
    />
    <path
      d="M30.6666 18.6665C24.0392 18.6665 18.6666 24.0391 18.6666 30.6665C18.6666 37.2939 24.0392 42.6665 30.6666 42.6665C37.291 42.659 42.6591 37.2909 42.6666 30.6665C42.6666 24.0391 37.2941 18.6665 30.6666 18.6665ZM30.6666 39.9998C25.512 39.9998 21.3333 35.8211 21.3333 30.6665C21.3333 25.5119 25.512 21.3332 30.6666 21.3332C35.8213 21.3332 40 25.5119 40 30.6665C39.9942 35.8188 35.8189 39.9941 30.6666 39.9998Z"
      fill="#ADC5FF"
    />
    <path
      d="M44.941 43.0558L40.0332 38.1479C39.476 38.8443 38.8442 39.4761 38.1478 40.0333L43.0556 44.9412C43.0567 44.9423 43.0577 44.9434 43.0587 44.9444C43.5803 45.4642 44.4244 45.4628 44.9442 44.9412C45.464 44.4198 45.4625 43.5756 44.941 43.0558Z"
      fill="#6488FF"
    />
  </svg>
);
