import { useState } from "react";
import { useAsyncFn } from "react-use";
import { createDangerNotification, log, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { DockDoor } from "@deliverr/sortation-client";
import { useSortationClient } from "sortation/base/useSortationClient";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";

export const useDockDoorSelect = () => {
  const [dockDoors, setDockDoors] = useState<DockDoor[]>([]);

  const sortationId = useRecoilValue(sortationIdState);
  const sortationClient = useSortationClient();
  const { addAutoCloseNotification } = useCommonFlow();

  const [getDockDoorsState, getDockDoors] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "getDockDoors" });

    try {
      const activeDockDoors = await sortationClient.getDockDoors(sortationId);
      setDockDoors(activeDockDoors);
      log(ctx, "Retrieved dock doors");
    } catch (err) {
      logError(ctx, err);

      addAutoCloseNotification(
        createDangerNotification("Failed to retrieve list of dock doors, please try again."),
        TOAST_AUTO_CLOSE
      );
    }
  });

  return {
    dockDoors,
    getDockDoors,
    isLoading: getDockDoorsState.loading,
  };
};
