import {
  ALL_PRINTERS_SEARCH_OPTIONS,
  connectedPrinterState,
  isSearchingPrintersState,
  isZebraScanner,
  log,
  printersState,
  usePrinterZebra,
} from "@deliverr/ui-facility";
import { useMount } from "react-use";
import { useRecoilValue } from "recoil";

export const usePrinterSelect = () => {
  const connectedPrinter = useRecoilValue(connectedPrinterState);
  const printers = useRecoilValue(printersState);
  const isSearchingPrinters = useRecoilValue(isSearchingPrintersState);
  const { initiatePrinterSearch, connectToPrinter } = usePrinterZebra(ALL_PRINTERS_SEARCH_OPTIONS);

  useMount(() => {
    if (isZebraScanner) {
      log({ fn: "usePrinterSelect" }, "zebra scanner, searching for printers");
      initiatePrinterSearch();
    }
  });

  return {
    connectedPrinter,
    printers,
    isSearchingPrinters,
    connectToPrinter,
    initiatePrinterSearch,
  };
};
