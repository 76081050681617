import { DefaultTheme, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { GaylordTitle } from "sortation/components/GaylordTitle";
import { useOutbound } from "./useOutbound";

const Center = styled.div<Record<string, unknown>, DefaultTheme>`
  margin-top: ${({ theme }) => theme.spacing.S2};
  text-align: center;
`;

export const Outbound = () => {
  const { carrier, validGaylord, sortCode, scanConfig } = useOutbound();

  return (
    <div>
      <GaylordTitle value={validGaylord} carrier={carrier} sortCode={sortCode} />
      <SimpleTextInputCard
        noTopBorderRadius
        title={scanConfig.title}
        label={scanConfig.label}
        value={scanConfig.value ?? ""}
        onSubmit={scanConfig.onSubmit}
        onChange={scanConfig.onChange}
        disabled={scanConfig.disabled}
        debounceSubsequent
      />
      {validGaylord && (
        <Center>
          <Text appearance="INFO">
            <FormattedMessage id="sortation.scanGaylord.scanAnotherGaylord" defaultMessage="or scan another gaylord" />
          </Text>
        </Center>
      )}
    </div>
  );
};
