import React, { useMemo } from "react";
import { DefaultTheme, Select } from "@deliverr/ui";
import { useMount } from "react-use";
import styled from "@emotion/styled";
import { LoadingSpinner } from "@deliverr/ui-facility";
import { SearchIcon } from "../PrinterSelect/SearchIcon";
import { useBrowserPrintSelect } from "./useBrowserPrinterSelect";

const StyledContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  width: 100%;
  background: ${theme.colors.NEUTRAL["00"]};
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.spacing.S2};
  padding: ${theme.spacing.S5};
  & > label:first-of-type {
    width: 100%;
  }
`
);

export const BrowserPrinterSelect = () => {
  const {
    initiatePrinterSearch,
    desktopPrinter,
    setDesktopPrinter,
    printers,
    isSearchingPrinters,
  } = useBrowserPrintSelect();

  const printerOptions = useMemo(() => printers.map((printer) => ({ value: printer.uid, label: printer.name })), [
    printers,
  ]);

  // Initiate printer "search" on component mount. This will preemptively set the selected printer the default printer if it's connected
  useMount(async () => await initiatePrinterSearch());

  const handlePrinterChange = (option) => {
    const filteredPrinters = printers.filter((printer) => printer.uid === option.value);
    setDesktopPrinter(filteredPrinters[0]);
  };

  return (
    <StyledContainer>
      {isSearchingPrinters ? (
        <LoadingSpinner size="2x" loading={isSearchingPrinters}>
          <SearchIcon />
        </LoadingSpinner>
      ) : (
        <Select
          label="Printer"
          placeholder="Select"
          value={{ value: desktopPrinter?.uid, label: desktopPrinter?.name }}
          options={printerOptions}
          onChange={handlePrinterChange}
          flexWidth={true}
        />
      )}
    </StyledContainer>
  );
};
