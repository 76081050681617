import { Button, ButtonIcon, DefaultTheme, Stack, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { DockDoorSelect } from "./components/DockDoorSelect";
import { useDockDoorLabel } from "./useDockDoorLabel";

const Container = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: ${theme.spacing.S3};
`
);

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S2} ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  display: flex;
  flex-direction: column;
`
);

const Spread = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  height: ${theme.spacing.S8};
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors.NEUTRAL["60"]};
  }
`
);

const PrintButton = styled(Button)`
  margin-top: auto;
`;

export const DockDoorLabel = () => {
  const {
    selectedDockDoor,
    setSelectedDockDoor,
    amount,
    isDecrementDisabled,
    incrementAmount,
    decrementAmount,
    printDockDoorLabels,
    printDockDoorLabelsState,
    hasConnectedPrinter,
  } = useDockDoorLabel();

  return (
    <Container>
      <Card>
        <DockDoorSelect selectedDockDoor={selectedDockDoor} setSelectedDockDoor={setSelectedDockDoor} />
        <Spread>
          <Text>Labels</Text>
          <Stack direction="HORIZONTAL">
            <ButtonIcon
              secondary
              disabled={isDecrementDisabled}
              onClick={decrementAmount}
              iconType="minus"
              aria-label="decrement label"
            />
            <span>{amount}</span>
            <ButtonIcon secondary onClick={incrementAmount} iconType="plus" aria-label="increment label" />
          </Stack>
        </Spread>
      </Card>
      <PrintButton
        disabled={printDockDoorLabelsState.loading || !hasConnectedPrinter || amount === 0}
        block
        onClick={printDockDoorLabels}
      >
        Print {amount} label{amount !== 1 ? "s" : ""}
      </PrintButton>
    </Container>
  );
};
