import React from "react";
import { ButtonIcon, DefaultTheme, Navbar, NavbarProps, defaultTheme } from "@deliverr/ui";
import {
  AppName,
  Flash,
  Page,
  PageContent,
  PageInner,
  VersionCheck,
  flashState,
  notificationsState,
  useRouter,
} from "@deliverr/ui-facility";
import { FlowVisibleNotifications } from "@deliverr/ui-facility/lib-facility/flow/FlowVisibleNotifications";
import { Menu } from "@deliverr/ui-facility/lib-facility/components/Menu/Menu";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { SortationModals } from "sortation/components/SortationModal/SortationModal";
import { navbarState } from "./navbarState";
import { sortationIdState } from "./sortationIdState";
import { sortationMenuConfig } from "./sortationMenuConfig";

const Inner = styled(PageInner)`
  display: grid;
  grid-template-rows: auto max-content;
  height: 100%;
  overflow-x: auto;
`;

const StyledNavbar = styled(Navbar)<NavbarProps, DefaultTheme>(
  ({ theme }) => `
  height: ${theme.spacing.S9}; // 64px Navbar
`
);

interface Props {
  name?: React.ReactNode;
  previousPage?: string;
}

export const SortationPage: React.FC<Props> = ({ name, previousPage, children }) => {
  const notifications = useRecoilValue(notificationsState);
  const flash = useRecoilValue(flashState);
  const navbar = useRecoilValue(navbarState);
  const router = useRouter();
  const sortationId = useRecoilValue(sortationIdState);

  return (
    <Page>
      <PageContent>
        <div>
          <StyledNavbar
            title={navbar.title ?? (name as string)}
            leftAction={
              previousPage ? (
                <ButtonIcon
                  iconType="times"
                  aria-label="close"
                  onClick={() => router.push(previousPage)}
                  transparent
                  size="LG"
                />
              ) : (
                <Menu
                  config={sortationMenuConfig}
                  iconColor={defaultTheme.colors.BLUE[300]}
                  app={AppName.SC}
                  facilityId={sortationId}
                  hasAuthEnabled={true}
                />
              )
            }
            rightAction={navbar.rightAction}
            thinNavbar={true}
          />
          <VersionCheck />
        </div>
        <Inner>
          <div>{children}</div>
          <FlowVisibleNotifications notifications={notifications} />
          <Flash flash={flash} />
        </Inner>
      </PageContent>
      <SortationModals />
    </Page>
  );
};
