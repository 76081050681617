import { DockDoor, DockDoorType } from "@deliverr/sortation-client";
import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

const Container = styled.span`
  display: flex;
  flex-direction: column;
`;

const BoldedText = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F4};
  line-height: 1.5rem;
`
);

const InfoText = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  font-size: ${theme.font.size.F4};
  font-weight: ${theme.font.weight.REGULAR};
  color: ${theme.colors.NEUTRAL[300]};
  line-height: 1.5rem;
  padding-bottom: ${theme.spacing.S2};
`
);

interface DockDoorInfoProps {
  dockDoor: DockDoor;
}

export const DockDoorInfo = ({ dockDoor }: DockDoorInfoProps) => {
  return (
    <Container>
      <BoldedText>Dock Door</BoldedText>
      <InfoText>{dockDoor.dockDoorName}</InfoText>
      <BoldedText>Zone Skip</BoldedText>
      <InfoText>{dockDoor.dockDoorType === DockDoorType.ZONE_SKIP ? "Yes" : "No"}</InfoText>
    </Container>
  );
};
