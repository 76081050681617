import React from "react";
import "@deliverr/ui-facility/lib-facility/index.css";
import { AppName, initializeZebraApp } from "@deliverr/ui-facility";
import ReactDOM from "react-dom";
import { injectScript, Script } from "./base/injectScript";
import { isMobile } from "./base/isMobile";
import SortationRoot from "./base/SortationRoot";
import { initDataDog } from "./utils/DataDog";

function renderApp() {
  const app = <SortationRoot />;
  ReactDOM.render(app, document.getElementById("root"));
}

const initializeSortation = async () => {
  // We include BrowserPrint for desktop browsers
  if (!isMobile) {
    await injectScript(Script.BrowserPrint);
    await injectScript(Script.Zebra);
  }
  initDataDog();
  initializeZebraApp(AppName.SC, renderApp);
};

void initializeSortation();
