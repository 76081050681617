import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { OutboundModal } from "./OutboundModal";

export const EmptyGaylordCodeModal: React.FC = () => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.scanGaylord.emptyGaylordTitle" defaultMessage="Scan gaylord first" />}
      text={
        <FormattedMessage
          id="sortation.scanGaylord.emptyGaylordBody"
          defaultMessage={"A gaylord must be chosen before assigning packages."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(OutboundModal.EMPTY_GAYLORD_CODE)}>
        <FormattedMessage id="sortation.scanGaylord.understandConfirm" defaultMessage="I understand" />
      </Button>
    </ModalTemplate>
  );
};
