import React from "react";
import { useMount } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationToWarehouseMap } from "@deliverr/ui-facility/lib-facility/hubs";
import { sortationIdState } from "../../base/sortationIdState";
import { LoadingSpinner } from "@deliverr/ui-facility";

export const HubAppRedirect: React.FC = () => {
  const sortationId = useRecoilValue(sortationIdState);

  useMount(() => {
    if (!sortationId) {
      window.location.replace(window.location.origin);
    } else {
      const warehouseId = sortationToWarehouseMap[sortationId];
      const url = [process.env.HUB_URL, "?warehouseid=", warehouseId].join("");
      window.location.replace(url);
    }
  });

  return <LoadingSpinner loading />;
};
