/**
 * This function parses tracking input for two different use cases:
 * 1. A tracking barcode is scanned or manually entered and the input is a non-json string, it is returned as is
 * 2. A Data Matrix is scanned, we parse the JSON, destructure the trackingCode, and return it
 * Sample ds of a encoded sort code data matrix: { sortCode: "ABC-D1", trackingCode: "123456789"}
 */
export const parseTrackingInput = (input: string): string => {
  try {
    // Attempt to parse as our DataMatrix object and destructure the tracking code
    const { trackingCode } = JSON.parse(input);

    // If trackingCode is undefined we want to return the input as is
    return trackingCode ?? input;
  } catch (err) {
    // DataMatrix can't be parsed, return input as is
    return input;
  }
};
