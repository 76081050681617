import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { DivertLaneModal } from "./DivertLaneModal";

export const AssignmentFailedCodeModal: React.FC = () => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={
        <FormattedMessage
          id="sortation.scanDivertLane.assignmentFailedTitle"
          defaultMessage="Gaylord assignment failed"
        />
      }
      text={
        <FormattedMessage
          id="sortation.scanDivertLane.assignmentFailedBody"
          defaultMessage={"The gaylord assignment failed. Scan again or try another divert lane."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
      data-testid="assignment_failed_code_modal"
    >
      <Button block onClick={() => hideModal(DivertLaneModal.ASSIGNMENT_FAILED)}>
        <FormattedMessage id="sortation.scanDivertLane.understandConfirm" defaultMessage="I understand" />
      </Button>
    </ModalTemplate>
  );
};
