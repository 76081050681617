import { DefaultTheme, Icon, IconsProps, Title, TitleProps } from "@deliverr/ui";
import { useRouter, isZebraScanner } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SortationPath } from "sortation/base/sortationPath";
import { isMobile } from "sortation/base/isMobile";

const ChooseLabelActionContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin: ${theme.spacing.S4};
  `
);

const StyledTitle = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S3};
`
);

const LabelActions = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    background: ${theme.colors.NEUTRAL["00"]};
    box-shadow: ${theme.shadow.S2};
    border-radius: ${theme.border.radius.R5};
  `
);

const LabelAction = styled.button<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.S5};
    color: ${theme.colors.NEUTRAL["500"]};
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.NEUTRAL["60"]};
    }
  `
);

const LabelActionIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
color: ${theme.colors.NEUTRAL["300"]};
`
);

const LabelActionButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
  <LabelAction {...props}>
    {children}
    <LabelActionIcon type="chevron-right" />
  </LabelAction>
);

export const LabelPrint = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();

  // If the scanner is a Zebra scanner, we want to redirect to the Zebra label print page
  if (isZebraScanner || isMobile) {
    router.push(SortationPath.ZEBRA_LABEL_PRINT);
    return null;
  }

  // If we are on a desktop, the user has the option of downloading the label or printing it
  return (
    <ChooseLabelActionContainer>
      <StyledTitle displayAs="h4" as="h2">
        {formatMessage({ id: "sortation.chooseLabelAction.title", defaultMessage: "Select action" })}
      </StyledTitle>
      <LabelActions>
        <LabelActionButton onClick={() => router.push(SortationPath.SHIPPING_LABEL_DOWNLOAD)}>
          {formatMessage({ id: "sortation.chooseLabelAction.gaylordLabelBtn", defaultMessage: "Download label" })}
        </LabelActionButton>
        <LabelActionButton onClick={() => router.push(SortationPath.ZEBRA_LABEL_PRINT)}>
          {formatMessage({ id: "sortation.chooseLabelAction.shippingLabelBtn", defaultMessage: "Print label" })}
        </LabelActionButton>
      </LabelActions>
    </ChooseLabelActionContainer>
  );
};
