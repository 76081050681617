const DIVERT_LANE_PREFIX = "DIVERT-";
const SORTER_ID_DELIMITER = "_SORTER_";

export function getSorterId(divertLaneCode: string, sortationCenterId: string): string | undefined {
  const match = divertLaneCode.match(/AS(\d+)-/);
  return match?.[1] ? sortationCenterId + SORTER_ID_DELIMITER + match[1] : undefined;
}

export function getDivertLaneId(divertLaneCode: string): string | undefined {
  const match = divertLaneCode.match(/DL(\d+)/);
  return match?.[1] ? DIVERT_LANE_PREFIX + match[1] : undefined;
}
