import React from "react";
import { FormattedMessage } from "react-intl";
import { noop } from "lodash/fp";
import { Input } from "@deliverr/ui";
import { useDebounceSubsequent } from "@deliverr/ui-facility/lib-facility/hooks/useDebounceSubsequent";
import { updateOnZebraScan } from "@deliverr/ui-facility/lib-facility/flow/zebraScanner";

interface Props {
  onSubmit: (val: string) => any;
  onChange: (val: string) => any;
  trackingCode: string;
  disabled: boolean;
  errorMessage: string;
}

// Reasonable debounce for scanning
const DEBOUNCE_TIME = 1000;

export const TrackingInput: React.FC<Props> = ({ onSubmit, onChange, trackingCode, disabled, errorMessage }) => {
  const debouncedFn = useDebounceSubsequent(onSubmit, DEBOUNCE_TIME);
  const scannerOnSubmit = disabled ? noop : debouncedFn;
  updateOnZebraScan(scannerOnSubmit);

  const prevDefaultOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (trackingCode) {
      onSubmit(trackingCode);
    }
  };

  return (
    <form onSubmit={prevDefaultOnSubmit} noValidate>
      <Input
        value={trackingCode}
        label={<FormattedMessage id="sortation.trackingInput.label" defaultMessage="Tracking Code" />}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
        disabled={disabled}
        hasError={errorMessage.length > 0}
        errorMessage={errorMessage}
      />
    </form>
  );
};
