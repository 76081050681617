import { DefaultTheme, Radio, RadioProps, ThemeProps } from "@deliverr/ui";
import { Printer } from "@deliverr/ui-facility/lib-facility/printer";
import styled from "@emotion/styled";
import React, { useState } from "react";

interface PrinterItemProps {
  printer: Printer;
  connectToPrinter: (printer: Printer) => Promise<void>;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
`;

const StyledText = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S3} 0;
`
);

const StyledRadio = styled(Radio)<RadioProps, DefaultTheme>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S4};
`
);

const PrinterName = styled.div<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-size: ${theme.font.size.F3};
  line-height: ${theme.font.lineHeight.LH3};
  text-align: left;
`
);

const PrinterStatus = styled.div<{ status: Status }, DefaultTheme>(
  ({ theme, status }) => `
  color: ${
    status === "connected"
      ? theme.colors.GREEN[400]
      : status === "connecting"
      ? theme.colors.NEUTRAL["100"]
      : theme.colors.NEUTRAL[300]
  };
  font-size: 14px;
  line-height: ${theme.font.lineHeight.LH2};
  text-align: left;
`
);

type Status = "connecting" | "connected" | "not connected";

export const PrinterItem: React.FC<PrinterItemProps> = ({ printer, connectToPrinter }) => {
  const [isConnecting, setConnecting] = useState<boolean>(false);

  const connect = async () => {
    setConnecting(true);
    await connectToPrinter(printer);
    setConnecting(false);
  };

  const status: Status = printer.isConnected ? "connected" : isConnecting ? "connecting" : "not connected";

  return (
    <StyledButton onClick={connect}>
      <StyledRadio
        checked={printer.isConnected || isConnecting}
        onChange={connect}
        onClick={(e) => e.stopPropagation()}
      />
      <StyledText>
        <PrinterName>{printer.deviceName}</PrinterName>
        <PrinterStatus status={status}>
          {printer.isConnected ? "Connected" : isConnecting ? "Connecting..." : "Not connected"}
        </PrinterStatus>
      </StyledText>
    </StyledButton>
  );
};
