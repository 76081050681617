import React from "react";
import { LoadToTrailerScanState, useLoadToTrailerScan } from "./useLoadToTrailerScan";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { BolTitle } from "sortation/pages/LoadToTrailerScan/components/BolTitle";
import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";

const ScanAnotherBolText = styled.p<{}, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S3};
    color: ${theme.colors.NEUTRAL[300]};
    text-align: center;
  `
);

export const LoadToTrailerScan = () => {
  const { bolId, trailerId, scanConfig, loadToTrailerScanState } = useLoadToTrailerScan();

  return (
    <div>
      <BolTitle bolId={bolId} trailerId={trailerId} loadToTrailerScanState={loadToTrailerScanState} />
      <SimpleTextInputCard
        noTopBorderRadius
        title={scanConfig.title}
        label={scanConfig.label}
        value={scanConfig.value ?? ""}
        onSubmit={scanConfig.onSubmit}
        onChange={scanConfig.onChange}
        disabled={scanConfig.disabled}
        debounceSubsequent
      />
      {loadToTrailerScanState === LoadToTrailerScanState.AWAITING_GAYLORD_SCAN && (
        <ScanAnotherBolText>or scan another BOL</ScanAnotherBolText>
      )}
    </div>
  );
};
