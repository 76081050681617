import React from "react";
import { FormattedMessage } from "react-intl";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { useArrivalScan } from "./useArrivalScan";

export const ArrivalScan: React.FC = () => {
  const { trackingCode, setTrackingCode, handleArrivalScan, handleArrivalScanState } = useArrivalScan();
  return (
    <SimpleTextInputCard
      title={<FormattedMessage id="sortation.arrival.title" defaultMessage="Scan packages on arrival" />}
      label={<FormattedMessage id="sortation.arrival.label" defaultMessage="Tracking code" />}
      value={trackingCode}
      onSubmit={handleArrivalScan}
      onChange={setTrackingCode}
      disabled={handleArrivalScanState.loading}
      debounceSubsequent
    />
  );
};
