import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { DefaultTheme, ButtonIcon, Icon, IconsProps, Title, Button, IconV2 } from "@deliverr/ui";

import { PrinterItem } from "./PrinterItem";
import { PrinterSettingsOverlay } from "./PrinterSettingsOverlay";
import { SearchIcon } from "./SearchIcon";
import { usePrinterSelect } from "./usePrinterSelect";
import { LoadingSpinner, Printer } from "@deliverr/ui-facility";

const SelectRadioButton = styled.button<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${theme.colors.NEUTRAL["00"]};
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.spacing.S2};
  padding: ${theme.spacing.S5};
  margin-top: ${theme.spacing.S4};
`
);

const SelectText = styled.span<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const StyledIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S4};
`
);

const RadioSelectOverlay = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${theme.colors.NEUTRAL[40]};
  z-index: 10;
`
);

const TopBar = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  display: flex;
  box-shadow: ${theme.shadow.S1};
  background: ${theme.colors.NEUTRAL["00"]};
  justify-content: space-between;
`
);

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
`;

const TopBarIconContainer = styled.div`
  margin: auto 0 auto auto;
`;

const SelectTitle = styled.h1<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S5};
  font-family: ${theme.font.family.TITLE};
  font-size: ${theme.font.size.F4};
  font-weight: ${theme.font.weight.BOLD};
`
);

const StyledFieldset = styled.fieldset<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin: ${theme.spacing.S6};
    padding: ${theme.spacing.S2} ${theme.spacing.S6};
    background: ${theme.colors.NEUTRAL["00"]};
    box-shadow: ${theme.shadow.S1};
    border-radius: ${theme.border.radius.R5};
    display: flex;
    flex-direction: column;
    & button:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.NEUTRAL["60"]};
    }
  `
);

const NoPrintersFound = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.S6};
  background: ${theme.colors.NEUTRAL["00"]};
  box-shadow: ${theme.shadow.S1};
  border-radius: ${theme.spacing.S2};
  padding: ${theme.spacing.S5};
`
);

const NoPrintersFoundText = styled(Title)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin: ${theme.spacing.S5} 0;
`
);

export interface PrinterSelectProps {
  printers?: Printer[];
}

export const PrinterSelect: React.FC<PrinterSelectProps> = () => {
  const [shouldShowPrinterSelection, setShouldShowPrinterSelection] = useState(false);
  const [shouldShowPrinterSettings, setShouldShowPrinterSettings] = useState(false);

  const {
    connectedPrinter,
    printers,
    isSearchingPrinters,
    connectToPrinter,
    initiatePrinterSearch,
  } = usePrinterSelect();

  const shouldShowTopBarSpinner = useMemo(() => !connectedPrinter && isSearchingPrinters && printers.length > 0, [
    connectedPrinter,
    isSearchingPrinters,
    printers.length,
  ]);

  const handleShowPrinterSelection = () => {
    setShouldShowPrinterSelection(true);

    /**
     * If we haven't connected a printer, but printers have been found previously, we want
     * to initiate a new printer search. This is because there's no option in the UI to
     * refresh the printer list when the application is in this state.
     */
    if (!isSearchingPrinters && !connectedPrinter && printers.length) {
      initiatePrinterSearch();
    }
  };

  return shouldShowPrinterSelection ? (
    <RadioSelectOverlay>
      <TopBar>
        <MenuTitle>
          <ButtonIcon
            iconType="times"
            aria-label="close"
            size="LG"
            transparent
            onClick={() => setShouldShowPrinterSelection(false)}
          />
          <SelectTitle>Connect to printer</SelectTitle>
        </MenuTitle>
        <TopBarIconContainer>
          {shouldShowTopBarSpinner && <IconV2 type="loading" size="medium" spin />}
          {connectedPrinter && (
            <ButtonIcon
              iconType="ellipsis-h"
              transparent
              size="LG"
              aria-label="printer menu"
              onClick={() => setShouldShowPrinterSettings(true)}
            />
          )}
        </TopBarIconContainer>
      </TopBar>
      {printers.length > 0 ? (
        <StyledFieldset>
          {printers.map((p) => (
            <PrinterItem key={p.ID} printer={p} connectToPrinter={connectToPrinter} />
          ))}
        </StyledFieldset>
      ) : (
        <NoPrintersFound>
          <LoadingSpinner loading={isSearchingPrinters}>
            <SearchIcon />
          </LoadingSpinner>
          <NoPrintersFoundText displayAs="h3">No printers found</NoPrintersFoundText>
          <Button block secondary disabled={isSearchingPrinters} onClick={initiatePrinterSearch}>
            Refresh printer list
          </Button>
        </NoPrintersFound>
      )}
      <PrinterSettingsOverlay
        visible={shouldShowPrinterSettings}
        toggleVisibility={() => setShouldShowPrinterSettings(false)}
      />
    </RadioSelectOverlay>
  ) : (
    <SelectRadioButton aria-label="printer select" onClick={handleShowPrinterSelection}>
      <span>Printer</span>
      <SelectText>
        {connectedPrinter?.deviceName ?? "Select"}
        <StyledIcon type="chevron-right" />
      </SelectText>
    </SelectRadioButton>
  );
};
