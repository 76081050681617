import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { UtilityScanModal } from "./UtilityScanModal";

export const CustomsOnHoldModal: React.FC = () => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.utilityScan.customsOnHoldModal.title" defaultMessage="Customs Hold" />}
      text={
        <FormattedMessage
          id="sortation.utilityScan.customsOnHoldModal.body"
          defaultMessage={
            "The scanned package has a customs hold. Please move this package to the bonded cage and continue with the next package."
          }
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(UtilityScanModal.CUSTOMS_ON_HOLD)}>
        <FormattedMessage id="sortation.utilityScan.customsOnHoldModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
