import { DockDoorError, SortationGaylordError, SortationGaylordWithDockDoor } from "@deliverr/sortation-client";
import { createSuccessNotification, useRouter } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useCallback, useState } from "react";
import { useAsyncFn } from "react-use";
import { useSetRecoilState } from "recoil";
import { navbarState } from "sortation/base/navbarState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { isDockDoor, isGaylord, isValidShipwellBolId } from "sortation/utils/stringValidation";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { dockDoorScanV2MessageMap, DockDoorScanV2Modal } from "./modals";
import { TextButton } from "sortation/components/TextButton";
import { SortationPath } from "sortation/base/sortationPath";

export const useDockDoorScanV2 = () => {
  // Global State
  const setNavbar = useSetRecoilState(navbarState);

  // Local State
  const [textInputValue, setTextInputValue] = useState<string>("");
  const [gaylordWithDockDoor, setGaylordWithDockDoor] = useState<SortationGaylordWithDockDoor>();
  const [didScanDockDoor, setDidScanDockDoor] = useState<boolean>(false);

  // Hooks
  const { addAutoCloseNotification, resetNotifications, successResponse, infoResponse, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();
  const router = useRouter();

  const sortationClient = useSortationClient();

  // Callback hook to reset the gaylord and other values
  const resetGaylordWithDockDoor = useCallback(() => {
    setTextInputValue("");
    setGaylordWithDockDoor(undefined);
    setNavbar({
      title: "Dock Doors",
      rightAction: undefined,
    });
  }, [setNavbar]);

  const handleError = useCallback(
    (err) => {
      const errCode = err.message;

      let modal: DockDoorScanV2Modal;

      switch (errCode) {
        case SortationGaylordError.NOT_FOUND:
          modal = DockDoorScanV2Modal.GAYLORD_NOT_FOUND;
          break;
        case SortationGaylordError.MISSING_GAYLORD_SORT_CODE:
          modal = DockDoorScanV2Modal.MISSING_GAYLORD_SORT_CODE;
          break;
        case SortationGaylordError.GAYLORD_DOCK_DOOR_MISMATCH:
          modal = DockDoorScanV2Modal.GAYLORD_DOCK_DOOR_MISMATCH;
          break;
        case SortationGaylordError.DOCK_DOOR_MAPPING_NOT_FOUND:
          modal = DockDoorScanV2Modal.DOCK_DOOR_MAPPING_NOT_FOUND;
          break;
        case DockDoorError.DOCK_DOOR_DOES_NOT_EXIST:
          modal = DockDoorScanV2Modal.DOCK_DOOR_DOES_NOT_EXIST;
          break;
        default:
          modal = DockDoorScanV2Modal.UNKNOWN_ERROR;
          break;
      }

      emitFlash("DANGER");
      showModal(modal, dockDoorScanV2MessageMap[modal]);
    },
    [emitFlash, showModal]
  );

  // Callback hook to handle gaylord scans
  const handleGaylordScan = useCallback(
    async (value: string) => {
      try {
        const gaylordWithDockDoorData = await sortationClient.getDockDoorForGaylord(value);

        // Update state
        setGaylordWithDockDoor(gaylordWithDockDoorData);
        setTextInputValue("");

        // Success sound, flash, and notification
        infoResponse();
        addAutoCloseNotification(createSuccessNotification(`${value} scanned.`), TOAST_AUTO_CLOSE);

        // Change nav bar to give the option to reset the gaylord
        setNavbar({
          title: "Dock Doors",
          rightAction: TextButton({ onClick: resetGaylordWithDockDoor, defaultMessage: "change" }),
        });
      } catch (err) {
        handleError(err);
      }
    },
    [addAutoCloseNotification, infoResponse, setNavbar, sortationClient, resetGaylordWithDockDoor, handleError]
  );

  // Callback hook to handle dock door scans
  const handleDockDoorScan = useCallback(
    async (value: string) => {
      // We can only process a dock door scan with a gaylord
      if (!gaylordWithDockDoor) {
        handleError(new Error(SortationGaylordError.NOT_FOUND));
        return;
      }

      try {
        await sortationClient.processAssignGaylordToDockDoorScan(gaylordWithDockDoor.gaylord.id, value);

        // Success sound, flash, and notification
        successResponse();
        addAutoCloseNotification(createSuccessNotification(`${value} scanned.`), TOAST_AUTO_CLOSE);
        resetGaylordWithDockDoor();
        setDidScanDockDoor(true);
      } catch (err) {
        handleError(err);
      }
    },
    [
      gaylordWithDockDoor,
      sortationClient,
      addAutoCloseNotification,
      successResponse,
      handleError,
      resetGaylordWithDockDoor,
      setDidScanDockDoor,
    ]
  );

  // Callback hook to handle BOL scans
  const handleBolScan = useCallback(
    async (value: string) => {
      addAutoCloseNotification(
        createSuccessNotification(`${value} scanned. Redirecting to BOL/Trailer Scan.`),
        TOAST_AUTO_CLOSE
      );
      router.push(`${SortationPath.LOAD_TO_TRAILER_SCAN}?bolId=${value}`);
    },
    [addAutoCloseNotification, router]
  );

  // Async hook to handle all submits and track loading state
  const [handleSubmitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      resetNotifications();
      setTextInputValue(value);

      const isValueGaylord = isGaylord(value);
      const isValueDockDoor = isDockDoor(value);
      const isValueShipwellBol = isValidShipwellBolId(value);

      // If it's a gaylord, get the gaylord data, and update the gaylord data
      if (isValueGaylord) {
        await handleGaylordScan(value);
      } else if (isValueDockDoor) {
        await handleDockDoorScan(value);
      } else if (isValueShipwellBol) {
        await handleBolScan(value);
      } else {
        handleError(DockDoorScanV2Modal.INVALID_BARCODE);
      }
    },
    [handleDockDoorScan, handleGaylordScan]
  );

  return {
    gaylordWithDockDoor,
    textInputValue,
    setTextInputValue,
    handleSubmit,
    handleSubmitState,
    didScanDockDoor,
  };
};
