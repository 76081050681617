import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { DivertLaneModal } from "./DivertLaneModal";

export const EmptyDivertLaneCodeModal: React.FC = () => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={
        <FormattedMessage id="sortation.scanDivertLane.emptyDivertLaneTitle" defaultMessage="Scan divert lane first" />
      }
      text={
        <FormattedMessage
          id="sortation.scanDivertLane.emptyDivertLaneBody"
          defaultMessage={"A divert lane must be chosen before assigning a gaylord."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(DivertLaneModal.EMPTY_DIVERT_LANE_CODE)}>
        <FormattedMessage id="sortation.scanDivertLane.understandConfirm" defaultMessage="I understand" />
      </Button>
    </ModalTemplate>
  );
};
