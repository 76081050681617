export enum LeftInBuildingReasonCode {
  EARLY_CARRIER_DEPARTURE = "EARLY_CARRIER_DEPARTURE",
  EXPECTED_CARRIER_CLOSURE = "EXPECTED_CARRIER_CLOSURE",
  FINANCIALLY_IMPRACTICAL = "FINANCIALLY_IMPRACTICAL",
  LATE_TRUCK_ARRIVAL = "LATE_TRUCK_ARRIVAL",
  MISSED_CARRIER_PICKUP = "MISSED_CARRIER_PICKUP",
  OPERATIONAL_MISS = "OPERATIONAL_MISS",
  OTHER = "OTHER",
  PARCEL_SUBSCRIPTION = "PARCEL_SUBSCRIPTION",
  TRUCK_CUBEOUT = "TRUCK_CUBEOUT",
  UNEXPECTED_CARRIER_CLOSURE = "UNEXPECTED_CARRIER_CLOSURE",
  WEATHER = "WEATHER",
}
