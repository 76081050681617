import { ModalInstance, ModalsState } from "@deliverr/ui-facility";
import { ReadOnlySelectorOptions, atom, selector } from "recoil";
import { SortationStateKey } from "sortation/base/SortationStateKeys";

export const visibleSortationModalsState = atom<ModalsState>({
  key: SortationStateKey.SORTATION_MODAL,
  default: {},
});

export const getVisibleModal: ReadOnlySelectorOptions<ModalInstance | undefined>["get"] = ({ get }) => {
  const visibleModals = get(visibleSortationModalsState);
  const modalInstance = Object.entries(visibleModals).find(([, props]) => !!props);

  if (!modalInstance) {
    return;
  }
  return {
    type: modalInstance[0],
    props: modalInstance[1],
  };
};

export const visibleSortationModalValue = selector<ModalInstance | undefined>({
  key: SortationStateKey.VISIBLE_MODAL,
  get: getVisibleModal,
});
