import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { UtilityScanModal } from "./UtilityScanModal";

export const UnknownErrorModal: React.FC = () => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.utilityScan.unknownErrorModal.title" defaultMessage="Unknown Error" />}
      text={
        <FormattedMessage
          id="sortation.utilityScan.unknownErrorModal.body"
          defaultMessage={"An unknown error occurred. Please try again."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(UtilityScanModal.CUSTOMS_UNKNOWN_ERROR)}>
        <FormattedMessage id="sortation.utilityScan.unknownErrorModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
