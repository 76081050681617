import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { WAREHOUSE_AUTH_AUDIENCE, logError, logStart } from "@deliverr/ui-facility";
import { sortationClientAuth0 } from "./Clients";

export const useSortationClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    const ctx = logStart({ fn: "useSortationClient" });

    // Get access token
    const accessToken = getAccessTokenSilently({ audience: WAREHOUSE_AUTH_AUDIENCE });
    accessToken?.then((token) => sortationClientAuth0.withAccessToken(token)).catch((e) => logError(ctx, e));

    // Append access token to requests
    sortationClientAuth0.addBeforeEach(async () => {
      await accessToken;
    });

    return sortationClientAuth0;
  }, [getAccessTokenSilently]);
};
