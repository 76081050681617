import React from "react";
import { Card, Input, Stack, DefaultTheme } from "@deliverr/ui";
import { useDebounceSubsequent } from "@deliverr/ui-facility/lib-facility/hooks/useDebounceSubsequent";
import { updateOnZebraScan } from "@deliverr/ui-facility/lib-facility/flow/zebraScanner";
import styled from "@emotion/styled";
import { noop } from "lodash/fp";

/**
 * SimpleTextInputCard is a basic version of TextInputCard.
 * There is no validation on input or scan.
 * This is mainly for scanning tracking codes where types of formats vary per carrier
 * ie. mmm349d, 342934902834902384, 3592359
 */

interface OwnProps {
  title: React.ReactNode;
  body?: React.ReactNode;
  onSubmit: (val: string) => any;
  onChange: (val: string) => any;
  value?: string;
  label: React.ReactNode;
  noTopBorderRadius?: boolean;
  disabled?: boolean;
  debounceSubsequent?: boolean;
}

const LongCard = styled(Card)<{ noTopBorderRadius?: boolean }, DefaultTheme>`
  /* design todo: fix radius */
  border-radius: ${({ noTopBorderRadius }) => (noTopBorderRadius ? `0 0 8px 8px` : `8px`)};
  padding: ${({ theme }) => `${theme.spacing.S5} ${theme.spacing.S5}`};
`;

const Body = styled.p<{}, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  font-size: ${theme.font.size.F3};
`
);

const Title = styled.p<{}, DefaultTheme>(
  ({ theme }) => `
  font-size: ${theme.font.size.F4};
  line-height: 24px;
  font-weight: ${theme.font.weight.BOLD};
`
);

const DEBOUNCE_TIME = 3000;

export const SimpleTextInputCard: React.FC<OwnProps> = ({
  title,
  body,
  onSubmit,
  onChange,
  value,
  label,
  noTopBorderRadius,
  disabled,
  debounceSubsequent,
}) => {
  const debouncedFn = useDebounceSubsequent(onSubmit, DEBOUNCE_TIME);
  const scannerOnSubmit = disabled ? noop : debounceSubsequent ? debouncedFn : onSubmit;
  updateOnZebraScan(scannerOnSubmit);

  const prevDefaultOnSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (value) {
      onSubmit(value);
    }
  };

  return (
    <LongCard noTopBorderRadius={noTopBorderRadius}>
      <Stack gap="S2">
        <Title>{title}</Title>
        {body && <Body>{body}</Body>}
        <form onSubmit={prevDefaultOnSubmit} noValidate>
          <Input
            data-testid="input"
            value={value}
            label={label}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
            required
            disabled={disabled}
          />
        </form>
      </Stack>
    </LongCard>
  );
};
