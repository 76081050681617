import React from "react";
import { FormattedMessage } from "react-intl";

import { ContinueButton, ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { OutboundModal } from "./OutboundModal";

export const IncorrectAssignmentModal: React.FC = () => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={
        <FormattedMessage id="sortation.scanGaylord.incorrectAssignment.title" defaultMessage="Incorrect assignment" />
      }
      text={
        <FormattedMessage
          id="sortation.scanGaylord.incorrectAssignment.body"
          defaultMessage={"This package does not belong in this gaylord. Return it to the sort queue."}
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
      data-testid="incorrect_assignment_modal"
    >
      <ContinueButton block onClick={() => hideModal(OutboundModal.INCORRECT_ASSIGNMENT)} />
    </ModalTemplate>
  );
};
