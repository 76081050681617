import { LeftInBuildingReasonCode } from "./LeftInBuildingReasonCode";

export const reasonCodeOptions = [
  { value: LeftInBuildingReasonCode.EARLY_CARRIER_DEPARTURE, label: "Early Carrier Departure" },
  { value: LeftInBuildingReasonCode.EXPECTED_CARRIER_CLOSURE, label: "Expected Carrier Closure" },
  { value: LeftInBuildingReasonCode.FINANCIALLY_IMPRACTICAL, label: "Financially Impractical" },
  { value: LeftInBuildingReasonCode.LATE_TRUCK_ARRIVAL, label: "Late Truck Arrival" },
  { value: LeftInBuildingReasonCode.MISSED_CARRIER_PICKUP, label: "Missed Carrier Pickup" },
  { value: LeftInBuildingReasonCode.OPERATIONAL_MISS, label: "Operational Miss" },
  { value: LeftInBuildingReasonCode.PARCEL_SUBSCRIPTION, label: "Parcel Subscription" },
  { value: LeftInBuildingReasonCode.TRUCK_CUBEOUT, label: "Truck Cubeout" },
  { value: LeftInBuildingReasonCode.UNEXPECTED_CARRIER_CLOSURE, label: "Unexpected Carrier Closure" },
  { value: LeftInBuildingReasonCode.WEATHER, label: "Weather" },
  { value: LeftInBuildingReasonCode.OTHER, label: "Other (Please specify)" },
];
