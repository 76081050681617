import React, { useState } from "react";
import { DefaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import {
  ALL_PRINTERS_SEARCH_OPTIONS,
  CenterSpinner,
  TextButton,
  usePrintZpl,
  usePrinterZebra,
} from "@deliverr/ui-facility";

interface PrinterSettingsOverlayProps {
  visible: boolean;
  toggleVisibility: () => void;
}

const SettingsOverlay = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(38, 46, 54, 0.5);
  z-index: 11;
`;

const SettingsList = styled.ul<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 11;
  padding: ${theme.spacing.S2} ${theme.spacing.S5};
  border-radius: ${theme.spacing.S2} ${theme.spacing.S2} 0px 0px;
  box-shadow: 0px 0px 2px rgba(218, 225, 232, 0.5), 0px 2px 4px rgba(218, 225, 232, 0.5);
`
);

const SettingsListItem = styled.li<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing.S3} 0px;
  &:not(:last-child) {
    box-shadow: inset 0px -1px 0px #E9EDF2;
  }
`
);

const SettingsListButton = styled(TextButton)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
`
);

export const PrinterSettingsOverlay: React.FC<PrinterSettingsOverlayProps> = ({ visible, toggleVisibility }) => {
  const { initiatePrinterSearch, disconnectPrinters } = usePrinterZebra(ALL_PRINTERS_SEARCH_OPTIONS);
  const { printTestLabelZpl } = usePrintZpl();

  const [isLoading, setLoading] = useState(false);

  const handleListItemClick = async (action) => {
    if (!isLoading) {
      setLoading(true);
      await action();
      setLoading(false);
      toggleVisibility();
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <SettingsOverlay>
      <CenterSpinner loading={isLoading} />
      <SettingsList>
        <SettingsListItem onClick={async () => await handleListItemClick(printTestLabelZpl)}>
          <SettingsListButton>Print test label</SettingsListButton>
        </SettingsListItem>
        <SettingsListItem onClick={async () => await handleListItemClick(disconnectPrinters)}>
          <SettingsListButton>Disconnect printer</SettingsListButton>
        </SettingsListItem>
        <SettingsListItem onClick={async () => await handleListItemClick(initiatePrinterSearch)}>
          <SettingsListButton>Refresh printer list</SettingsListButton>
        </SettingsListItem>
        <SettingsListItem onClick={toggleVisibility}>
          <SettingsListButton>Cancel</SettingsListButton>
        </SettingsListItem>
      </SettingsList>
    </SettingsOverlay>
  );
};
