import { SortationCenterError } from "@deliverr/sortation-client";
import {
  createDangerNotification,
  createSuccessNotification,
  notificationsState,
  SoundFx,
} from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn, useUnmount } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

export const useArrivalScan = () => {
  const { formatMessage } = useIntl();
  const notifications = useRecoilValue(notificationsState);
  const { playSfx, addAutoCloseNotification, resetNotifications, emitFlash, customResponse } = useCommonFlow();
  const [trackingCode, setTrackingCode] = useState<string>("");
  const { handleSortationCenterError } = useSortationCenterError();
  const sortationClient = useSortationClient();
  const sortationId = useRecoilValue(sortationIdState);

  const errorResponse = useMemo(() => customResponse(SoundFx.ERROR_FAST, "DANGER"), [customResponse]);

  useUnmount(() => {
    resetNotifications();
  });

  const [handleArrivalScanState, handleArrivalScan] = useAsyncFn(
    async (input: string) => {
      const code = parseTrackingInput(input);

      // Updates state to visually show the tracking code in the input field on scan
      setTrackingCode(code);
      try {
        if (notifications.length) {
          resetNotifications();
        }
        await sortationClient.processArrivalScan(code, sortationId!);
        playSfx(SoundFx.SUCCESS);
        emitFlash("SUCCESS");
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{code} scanned.`,
              },
              { code }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
          handleSortationCenterError();
          return;
        }
        errorResponse();
        addAutoCloseNotification(
          createDangerNotification(
            formatMessage({
              id: "sortation.arrival.danger",
              defaultMessage: "Remove unrecognized package.",
            })
          ),
          TOAST_AUTO_CLOSE
        );
      } finally {
        setTrackingCode("");
      }
      return;
    },
    [notifications, sortationId]
  );

  return {
    trackingCode,
    setTrackingCode,
    handleArrivalScan,
    handleArrivalScanState,
  };
};
