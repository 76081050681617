import { Button, DefaultTheme } from "@deliverr/ui";
import { ModalTemplate } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { OutboundModal } from "./OutboundModal";

interface IProps {
  name: string;
  onConfirm: () => void;
}

const ButtonContainer = styled.div<{}, DefaultTheme>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.S3};
`;

export const ShippingLabelConfirmation: React.FC<IProps> = ({ name, onConfirm }) => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={
        <FormattedMessage
          id="sortation.printLabel.shipping.confirmationModal.header"
          defaultMessage="Is this package for {name}?"
          values={{ name }}
        />
      }
      text={
        <FormattedMessage
          id="sortation.printLabel.shipping.confirmationModal.text"
          defaultMessage="Verify the name matches what's on the package."
        />
      }
    >
      <ButtonContainer>
        <Button block onClick={onConfirm}>
          <FormattedMessage
            id="sortation.printLabel.shipping.confirmationModal.confirm"
            defaultMessage="Confirm and print"
          />
        </Button>
        <Button block onClick={() => hideModal(OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION)} secondary>
          <FormattedMessage id="sortation.printLabel.shipping.confirmationModal.cancel" defaultMessage="Cancel" />
        </Button>
      </ButtonContainer>
    </ModalTemplate>
  );
};
