export enum Script {
  BrowserPrint = "https://static.deliverr.com/print/BrowserPrint-3.0.216.min.js",
  Zebra = "https://static.deliverr.com/print/BrowserPrint-Zebra-1.0.216.min.js",
}

export const injectScript = async (script: Script) => {
  return await new Promise<void>((resolve) => {
    const head = document.getElementsByTagName("head")[0];
    const js = document.createElement("script");
    js.type = "text/javascript";
    js.src = script;
    js.onload = () => resolve();
    head.appendChild(js);
  });
};
