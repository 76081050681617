import { useState } from "react";
import { SortationCenterError } from "@deliverr/sortation-client";
import { connectedPrinterState, createDangerNotification, log, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { isNil } from "lodash/fp";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { desktopPrinterState } from "sortation/base/BrowserPrint";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationPrint } from "sortation/base/useSortationPrint";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

const MAX_INCREMENT_VALUE = 200;

export const useGaylordPrint = () => {
  const { formatMessage } = useIntl();
  const sortationId = useRecoilValue(sortationIdState);

  const sortationClient = useSortationClient();

  // Mobile Printing Recoil State
  const hasConnectedPrinter = useRecoilValue(connectedPrinterState);

  // Desktop Printing Recoil State
  const desktopPrinter = useRecoilValue(desktopPrinterState);

  const { addAutoCloseNotification } = useCommonFlow();
  const [amount, setAmount] = useState<number>(0);
  const { print } = useSortationPrint();
  const { handleSortationCenterError } = useSortationCenterError();
  const incrementAmount = () => setAmount((num) => num + 1);
  const decrementAmount = () => setAmount((num) => num - 1);
  const isDecrementDisabled = amount === 0;
  const isIncrementDisabled = amount === MAX_INCREMENT_VALUE;

  const [createGaylordState, createGaylords] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "createGaylords", amount });
    try {
      const gaylordData = await sortationClient.createGaylords(amount, sortationId!);
      log(ctx, "Created gaylords");

      // Print each label individually while awaiting for a successful response
      for (const gaylord of gaylordData.sortationGaylords) {
        await print(gaylord.labelContents);
      }
    } catch (err) {
      logError(ctx, err);
      if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        handleSortationCenterError();
        return;
      }
      addAutoCloseNotification(
        createDangerNotification(
          formatMessage({
            id: "sortation.gaylordPrint.createGaylordError",
            defaultMessage: "Something went wrong, please try again.",
          })
        ),
        TOAST_AUTO_CLOSE
      );
    }
  }, [amount, sortationId]);

  return {
    amount,
    isDecrementDisabled,
    isIncrementDisabled,
    incrementAmount,
    decrementAmount,
    createGaylords,
    createGaylordState,
    hasConnectedPrinter: !isNil(hasConnectedPrinter) || !isNil(desktopPrinter),
  };
};
