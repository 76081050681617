import React from "react";
import { useRecoilValue } from "recoil";
import { sortationModalMap } from "./SortationModalMap";
import { visibleSortationModalValue } from "./SortationModalState";

export const SortationModals: React.FC = () => {
  const visibleModal = useRecoilValue(visibleSortationModalValue);
  if (!visibleModal) {
    return null;
  }
  const Modal = sortationModalMap[visibleModal.type];
  return <Modal {...visibleModal.props} />;
};
