import { useState } from "react";
import { SortationGaylordError, SortationPackageError } from "@deliverr/sortation-client";
import { SoundFx, createSuccessNotification } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useAsyncFn, useUnmount } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navbarState } from "sortation/base/navbarState";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { isGaylord } from "sortation/utils/stringValidation";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { TextButton } from "../../components/TextButton";
import { OutboundModal } from "./modals";

export const useOutbound = () => {
  const { formatMessage } = useIntl();
  const setNavbar = useSetRecoilState(navbarState);
  const [validGaylord, setValidGaylord] = useState<string | undefined>(undefined);
  const [gaylord, setGaylord] = useState<string | undefined>(undefined);
  const [trackingCode, setTrackingCode] = useState<string | undefined>(undefined);
  const [carrier, setCarrier] = useState<string | undefined>(undefined);
  const [sortCode, setSortCode] = useState<string | undefined>(undefined);
  const { playSfx, addAutoCloseNotification, resetNotifications, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();
  const sortationClient = useSortationClient();
  const sortationId = useRecoilValue(sortationIdState);

  useUnmount(() => {
    resetNotifications();
    setNavbar({});
  });

  const showInvalidModal = () => {
    emitFlash("DANGER");
    showModal(OutboundModal.INVALID_GAYLORD_CODE, {});
  };

  const resetGaylord = () => {
    setGaylord(undefined);
    setValidGaylord(undefined);
    setCarrier(undefined);
    setTrackingCode(undefined);
    setSortCode(undefined);
    setNavbar({
      title: formatMessage({ id: "sortation.name.scanGaylord", defaultMessage: "Scan Gaylord" }),
      rightAction: undefined,
    });
  };

  const [handleGaylordChangeState, handleGaylordChange] = useAsyncFn(
    async (value: string) => {
      resetNotifications();
      if (!value) {
        emitFlash("DANGER");
        showModal(OutboundModal.EMPTY_GAYLORD_CODE, {});
        return;
      }

      setGaylord(value);
      if (isGaylord(value)) {
        try {
          const registerGaylordRes = await sortationClient.registerGaylordForPackages(value);
          setCarrier(registerGaylordRes.carrier ?? undefined);
          setSortCode(registerGaylordRes.sortCode ?? undefined);
          setValidGaylord(value);
          playSfx(SoundFx.INFO);
          emitFlash("SUCCESS");
          setNavbar({
            title: formatMessage({ id: "sortation.name.loadGaylord", defaultMessage: "Load Gaylord" }),
            rightAction: TextButton({ onClick: resetGaylord, defaultMessage: "change" }),
          });
          addAutoCloseNotification(
            createSuccessNotification(
              formatMessage(
                {
                  id: "sortation.successScan",
                  defaultMessage: `{code} scanned.`,
                },
                { code: value }
              )
            ),
            TOAST_AUTO_CLOSE
          );
        } catch (err) {
          showInvalidModal();
        }
      } else {
        showInvalidModal();
      }
    },
    [sortationId]
  );

  const [handleTrackingCodeScanState, handleTrackingCodeScan] = useAsyncFn(
    async (input: string) => {
      resetNotifications();
      // When in the tracking code mapping flow, the user has the option to change the gaylord by scanning one.
      // if the scan is a gaylord, we'll have to switch over to handleGaylordChange fn.
      if (isGaylord(input)) {
        await handleGaylordChange(input);
        setTrackingCode(undefined);
        return;
      }

      const code = parseTrackingInput(input);

      setTrackingCode(code);
      try {
        const assignPackageRes = await sortationClient.processAssignPackageToGaylordScan(code, validGaylord!);
        setCarrier(assignPackageRes.gaylord.carrier);
        setSortCode(assignPackageRes.gaylord.sortCode ?? undefined);
        playSfx(SoundFx.SUCCESS);
        emitFlash("SUCCESS");
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{code} scanned.`,
              },
              { code }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        emitFlash("DANGER");
        if (
          err.code === SortationGaylordError.CARRIER_MISSORT ||
          err.code === SortationPackageError.SORT_CODE_MISSORT
        ) {
          showModal(OutboundModal.INCORRECT_ASSIGNMENT, {});
        } else {
          showModal(OutboundModal.UNRECOGNIZED_TRACKING_CODE, {});
        }
      } finally {
        setTrackingCode("");
      }
    },
    [validGaylord]
  );

  // Determines when to use the tracking code scan or Gaylord scan functions.
  const scanConfig = validGaylord
    ? {
        title: formatMessage({
          id: "sortation.gaylordTrackingScan.title",
          defaultMessage: "Scan tracking code to assign to gaylord",
        }),
        label: formatMessage({ id: "sortation.gaylordTrackingScan.label", defaultMessage: "Tracking code" }),
        value: trackingCode,
        onSubmit: handleTrackingCodeScan,
        onChange: setTrackingCode,
        disabled: handleGaylordChangeState.loading,
      }
    : {
        title: formatMessage({ id: "sortation.gaylordScan.title", defaultMessage: "Scan gaylord barcode" }),
        label: formatMessage({ id: "sortation.gaylordScan.label", defaultMessage: "Gaylord code" }),
        value: gaylord,
        onSubmit: handleGaylordChange,
        onChange: setGaylord,
        disabled: handleTrackingCodeScanState.loading,
      };

  return {
    carrier,
    validGaylord,
    sortCode,
    scanConfig,
  };
};
