import React, { useMemo } from "react";
import { useDockDoorScanV2 } from "./useDockDoorScanV2";
import { GaylordTitle } from "sortation/components/GaylordTitle";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { DockDoorInfo } from "./components";

const ScanAnotherText = styled.p<{}, DefaultTheme>(
  ({ theme }) => `
    margin-top: ${theme.spacing.S3};
    color: ${theme.colors.NEUTRAL[300]};
    text-align: center;
  `
);

export const DockDoorScanV2 = () => {
  const {
    gaylordWithDockDoor,
    textInputValue,
    setTextInputValue,
    handleSubmit,
    handleSubmitState,
    didScanDockDoor,
  } = useDockDoorScanV2();

  // SimpleTextInputCard "title" value change's based on if we have a gaylord or not.
  const textInputTitle = useMemo<React.ReactNode>(() => {
    return gaylordWithDockDoor?.dockDoor ? (
      <DockDoorInfo dockDoor={gaylordWithDockDoor.dockDoor} />
    ) : (
      "Scan gaylord to assign to dock door"
    );
  }, [gaylordWithDockDoor]);

  return (
    <>
      <GaylordTitle
        value={gaylordWithDockDoor?.gaylord.id}
        carrier={gaylordWithDockDoor?.gaylord.carrier}
        sortCode={gaylordWithDockDoor?.gaylord.sortCode}
      />
      <SimpleTextInputCard
        noTopBorderRadius
        title={textInputTitle}
        label={gaylordWithDockDoor ? "Dock Door Barcode" : "Gaylord Barcode"}
        value={textInputValue ?? ""}
        onSubmit={handleSubmit}
        onChange={setTextInputValue}
        disabled={handleSubmitState.loading}
        debounceSubsequent
      />
      {gaylordWithDockDoor ? (
        <ScanAnotherText>or scan another gaylord</ScanAnotherText>
      ) : didScanDockDoor ? (
        <ScanAnotherText>or scan a Shipwell BOL to switch to BOL/Trailer scan</ScanAnotherText>
      ) : null}
    </>
  );
};
