import { defaultTheme, IconV2Props } from "@deliverr/ui";

export const sortationDangerIconProps: IconV2Props = {
  type: "exclamation-circle",
  color: defaultTheme.colors.RED[300],
  size: "3x",
};

export const sortationWarningIconProps: IconV2Props = {
  type: "exclamation-circle",
  color: defaultTheme.colors.YELLOW[300],
  size: "3x",
};
