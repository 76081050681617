import { FC } from "react";
import { CustomsOnHoldModal } from "./CustomsOnHoldModal";
import { TrackingCodeNotFoundModal } from "./TrackingCodeNotFoundModal";
import { UnknownCustomsStatusModal } from "./UnknownCustomsStatusModal";
import { UnknownErrorModal } from "./UnknownErrorModal";
import { UtilityScanModal } from "./UtilityScanModal";

export const utilityScanModalMap: Record<UtilityScanModal, FC<any>> = {
  [UtilityScanModal.CUSTOMS_ON_HOLD]: CustomsOnHoldModal,
  [UtilityScanModal.CUSTOMS_UNKNOWN_ERROR]: UnknownErrorModal,
  [UtilityScanModal.TRACKING_CODE_NOT_FOUND]: TrackingCodeNotFoundModal,
  [UtilityScanModal.UNKNOWN_CUSTOMS_STATUS]: UnknownCustomsStatusModal,
};
