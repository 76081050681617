import React from "react";
import { isEmpty } from "lodash/fp";
import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { BarcodeInput } from "./BarcodeInput";
import { TaskSelect } from "./TaskSelect";
import { useUtilityScan } from "./useUtilityScan";

const UtilityScanContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.S3};
  width: calc(100vw - ${theme.spacing.S5}) // Prevent button from overflowing off screen from long task names
`
);

export const UtilityScan = () => {
  const { selectedTask, barcode, isLoading, setSelectedTask, setBarcode, handleUtilityScan } = useUtilityScan();

  return (
    <UtilityScanContainer>
      <TaskSelect selectedTask={selectedTask} setSelectedTask={setSelectedTask} />
      <BarcodeInput
        visible={!isEmpty(selectedTask)}
        disabled={isLoading || !selectedTask}
        barcode={barcode}
        setBarcode={setBarcode}
        handleUtilityScan={handleUtilityScan}
      />
    </UtilityScanContainer>
  );
};
