import { useEffect, useState } from "react";
import { AppName, log, setLogUser, useUserState, userState } from "@deliverr/ui-facility";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useSortationFeatureClient } from "./SortationFeature";
import { sortationIdState } from "./sortationIdState";

export const useSortationApp = () => {
  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const { isClientInitialized } = useSortationFeatureClient();

  // Global State
  useUserState(AppName.SC);
  const { sortationId, email } = useRecoilValue(userState);
  const setSortationIdState = useSetRecoilState(sortationIdState);

  useEffect(() => {
    const ctx = { fn: "useSortationApp" };

    if (sortationId) {
      setSortationIdState(sortationId);
      log(ctx, "setting user log props", { sortationId, email });
      setLogUser({ sortationId, email });

      if (isClientInitialized) {
        setIsAppReady(true);
      }
    }
  }, [sortationId, email, setSortationIdState, isClientInitialized]);

  return { isAppReady };
};
