import React, { useMemo } from "react";
import { useLeftInBuildingScan } from "./useLeftInBuildingScan";
import { GaylordTitle } from "sortation/components/GaylordTitle";
import { SimpleTextInputCard } from "sortation/components/SimpleTextInputCard";
import { Select, Input, ThemeProps } from "@deliverr/ui";
import { LeftInBuildingReasonCode } from "./LeftInBuildingReasonCode";
import { reasonCodeOptions } from "./ReasonCodeOptions";
import { isUndefined } from "lodash";
import styled from "@emotion/styled";

const LIBReasonFormContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S2};
`
);

export const LeftInBuildingScan = () => {
  const {
    gaylord,
    textInputValue,
    setTextInputValue,
    handleSubmit,
    handleSubmitState,
    reasonCode,
    handleReasonCodeChange,
    reasonDetails,
    handleReasonDetailsChange,
  } = useLeftInBuildingScan();

  const libReasonForm = useMemo<React.ReactNode>(() => {
    return (
      <LIBReasonFormContainer>
        <Select
          placeholder="Reason Code"
          label="Select a left in building reason"
          value={reasonCodeOptions.find((opt) => opt.value === reasonCode)}
          options={reasonCodeOptions}
          onChange={handleReasonCodeChange}
        />
        {reasonCode === LeftInBuildingReasonCode.OTHER && (
          <Input
            value={reasonDetails}
            placeholder="Reason Details"
            label="Enter left in building details"
            onChange={handleReasonDetailsChange}
          />
        )}
      </LIBReasonFormContainer>
    );
  }, [reasonCode, reasonDetails, handleReasonCodeChange, handleReasonDetailsChange]);

  return (
    <>
      <GaylordTitle value={gaylord?.id} carrier={gaylord?.carrier} sortCode={gaylord?.sortCode} />
      <SimpleTextInputCard
        noTopBorderRadius
        title="Scan Gaylords as Left In Building"
        label="Gaylord Barcode"
        value={textInputValue ?? ""}
        onSubmit={handleSubmit}
        onChange={setTextInputValue}
        disabled={
          handleSubmitState.loading ||
          isUndefined(reasonCode) ||
          (reasonCode === LeftInBuildingReasonCode.OTHER && reasonDetails.length === 0)
        }
        debounceSubsequent
        body={libReasonForm}
      />
    </>
  );
};
