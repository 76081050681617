import { DefaultTheme, Icon, IconsProps, Title, TitleProps } from "@deliverr/ui";
import { useRouter } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { isMobile } from "sortation/base/isMobile";
import { SortationPath } from "sortation/base/sortationPath";
import { BrowserPrinterSelect } from "sortation/components/BrowserPrinterSelect/BrowserPrinterSelect";
import { PrinterSelect } from "sortation/components/PrinterSelect";

const LabelPrinterContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    margin: ${theme.spacing.S4};
  `
);

const PrinterSelectContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S5};
`
);

const StyledTitle = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S3};
`
);

const LabelTypes = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    background: ${theme.colors.NEUTRAL["00"]};
    box-shadow: ${theme.shadow.S2};
    border-radius: ${theme.border.radius.R5};
  `
);

const LabelType = styled.button<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.S5};
    color: ${theme.colors.NEUTRAL["500"]};
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.NEUTRAL["60"]};
    }
  `
);

const LabelTypeIcon = styled(Icon)<IconsProps, DefaultTheme>(
  ({ theme }) => `
color: ${theme.colors.NEUTRAL["300"]};
`
);

const LabelPrintButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
  <LabelType {...props}>
    {children}
    <LabelTypeIcon type="chevron-right" />
  </LabelType>
);

export const ZebraLabelPrint = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();

  return (
    <LabelPrinterContainer>
      <PrinterSelectContainer>{isMobile ? <PrinterSelect /> : <BrowserPrinterSelect />}</PrinterSelectContainer>
      <StyledTitle displayAs="h4" as="h2">
        {formatMessage({ id: "sortation.labelprint.title", defaultMessage: "Select label type" })}
      </StyledTitle>
      <LabelTypes>
        <LabelPrintButton onClick={() => router.push(SortationPath.GAYLORD_LABEL)}>
          {formatMessage({ id: "sortation.labelprint.gaylordLabelBtn", defaultMessage: "Gaylord label" })}
        </LabelPrintButton>
        <LabelPrintButton onClick={() => router.push(SortationPath.SHIPPING_LABEL)}>
          {formatMessage({ id: "sortation.labelprint.shippingLabelBtn", defaultMessage: "Shipping label" })}
        </LabelPrintButton>
        <LabelPrintButton onClick={() => router.push(SortationPath.DOCK_DOOR_LABEL)}>
          {formatMessage({ id: "sortation.labelprint.dockDoorLabelBtn", defaultMessage: "Dock door label" })}
        </LabelPrintButton>
      </LabelTypes>
    </LabelPrinterContainer>
  );
};
