import { useMemo, useState } from "react";
import { SortationCenterError } from "@deliverr/sortation-client";
import { SoundFx, createSuccessNotification, log, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationCenterError } from "sortation/base/useSortationCenterError";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { OutboundModal } from "sortation/pages/Outbound/modals";
import { parseTrackingInput } from "sortation/utils/parseTrackingInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

export const useShippingLabelDownload = () => {
  const { formatMessage } = useIntl();
  const { playSfx, addAutoCloseNotification, resetNotifications, customResponse } = useCommonFlow();
  const { handleSortationCenterError } = useSortationCenterError();
  const { showModal, hideModal } = useSortationModal();
  const sortationId = useRecoilValue(sortationIdState);
  const sortationClient = useSortationClient();

  const [trackingCode, setTrackingCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const errorResponse = useMemo(() => customResponse(SoundFx.ERROR_FAST, "DANGER"), [customResponse]);

  const handleError = (message: string) => {
    errorResponse();
    setErrorMessage(
      formatMessage({
        id: "sortation.shippingLabelDownload.error",
        defaultMessage: message,
      })
    );
  };

  // Attempt to decode and download the label on modal confirmation
  const onModalConfirm = async (labelUrl: string) => {
    const ctx = logStart({ fn: "useShippingLabelDownload.onModalConfirm", labelUrl });
    try {
      window.open(labelUrl, "_blank");

      // Reset state for success
      setTrackingCode("");
      addAutoCloseNotification(
        createSuccessNotification(
          formatMessage({
            id: "sortation.shippingLabelDownload.success",
            defaultMessage: "Label opened in new tab",
          })
        ),
        TOAST_AUTO_CLOSE
      );
    } catch (err) {
      logError(ctx, err);

      // If the error is thrown after the success toast we want to reset the toast notifications
      resetNotifications();
      handleError("Unable to download label");
    } finally {
      hideModal(OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION);
    }
  };

  const [handleRedownloadState, handleRedownload] = useAsyncFn(async (input: string) => {
    const ctx = logStart({ fn: "useShippingLabelDownload.onDownloadButtonClick", input, sortationId });

    const code = parseTrackingInput(input);

    // Ensure our state is correct prior to handling a redownload
    setTrackingCode(code);
    setErrorMessage("");
    resetNotifications();

    try {
      const { recipientName, labelUrl } = await sortationClient.reprintShippingLabelAsPdf(code, sortationId!);
      log(ctx, "retrieved recipient name and label URL");

      // Play a success sound and show a confirmation modal on success
      playSfx(SoundFx.SUCCESS);
      showModal(OutboundModal.REPRINT_SHIPPING_LABEL_CONFIRMATION, {
        name: recipientName,
        onConfirm: async () => await onModalConfirm(labelUrl),
      });
    } catch (err) {
      logError(ctx, err);
      if (err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        handleSortationCenterError();
        return;
      }

      handleError("Invalid tracking code");
    }
  });

  return {
    trackingCode,
    setTrackingCode,
    errorMessage,
    handleRedownload,
    handleRedownloadState,
  };
};
