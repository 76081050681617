import React from "react";
import { ContinueButton, ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { DockDoorScanV2Modal } from "./DockDoorScanV2Modal";

export interface DockDoorScanV2ErrorModalProps {
  title: string;
  text: string;
  hideModalValue: DockDoorScanV2Modal;
}

export const DockDoorScanV2ErrorModal: React.FC<DockDoorScanV2ErrorModalProps> = ({ title, text, hideModalValue }) => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.dockDoorScanV2.errorModalTitle" defaultMessage={title} />}
      text={<FormattedMessage id="sortation.dockDoorScanV2.errorModalText" defaultMessage={text} />}
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <ContinueButton block onClick={() => hideModal(hideModalValue)} />
    </ModalTemplate>
  );
};
