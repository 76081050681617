import { useRecoilValue } from "recoil";
import { BrowserPrintDevice } from "./BrowserPrintTypes";
import { desktopPrinterState } from "./browserPrintState";

export const useBrowserPrint = () => {
  const desktopPrinter = useRecoilValue(desktopPrinterState);

  const BrowserPrint = window?.BrowserPrint;
  const Zebra = window?.Zebra;

  const getLocalDevices = async () => {
    return await new Promise<BrowserPrintDevice[]>((resolve, reject) => {
      BrowserPrint.getLocalDevices(
        (deviceList) => resolve(deviceList?.printers ?? []),
        (error) => reject(new Error(error?.length ? error : "Error getting printers"))
      );
    });
  };

  const getDefaultDevice = async () => {
    return await new Promise<BrowserPrintDevice>((resolve, reject) => {
      BrowserPrint.getDefaultDevice(
        "printer",
        (device: BrowserPrintDevice) => resolve(device),
        (error) => reject(new Error(error?.length ? error : "Error getting default printer"))
      );
    });
  };

  const isPrinterReady = async (printer) => {
    return await new Promise<boolean>((resolve) => {
      const zebraPrinter = new Zebra.Printer(printer);

      zebraPrinter
        .isPrinterReady()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  };

  const print = async (zpl: string) => {
    const isReady = await isPrinterReady(desktopPrinter);

    return await new Promise<void>((resolve, reject) => {
      if (desktopPrinter) {
        if (isReady) {
          desktopPrinter.send(
            zpl,
            () => resolve(),
            () => reject(new Error("Error printing label"))
          );
        } else {
          reject(new Error(`Printer: ${desktopPrinter.name} is not ready to print`));
        }
      } else {
        reject(new Error("No printer selected"));
      }
    });
  };

  return {
    getLocalDevices,
    getDefaultDevice,
    isPrinterReady,
    print,
  };
};
