import React from "react";
import { Card, CardProps, DefaultTheme, Input, Text, TextProps, ThemeProps, Title, TitleProps } from "@deliverr/ui";
import { useDebounceSubsequent } from "@deliverr/ui-facility/lib-facility/hooks/useDebounceSubsequent";
import { updateOnZebraScan } from "@deliverr/ui-facility/lib-facility/flow/zebraScanner";
import styled from "@emotion/styled";
import { noop } from "lodash/fp";

interface BarcodeInputProps {
  visible: boolean;
  disabled: boolean;
  barcode: string;
  setBarcode: (value: string) => void;
  handleUtilityScan: (code: string) => Promise<void>;
}

interface StyledCardProps extends CardProps {
  visible: boolean;
}

const StyledCard = styled(Card)<StyledCardProps, DefaultTheme>(
  ({ theme, visible }) => `
  display: ${visible ? "block" : "none"};
  margin-top: ${theme.spacing.S3};
  box-shadow: 0px 0px 2px rgba(218, 225, 232, 0.5), 0px 2px 4px rgba(218, 225, 232, 0.5);
  border-radius: ${theme.border.radius.R5};
`
);

const StyledTitle = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  margin-bottom: ${theme.spacing.S2};
`
);

const StyledDescription = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  margin-bottom: ${theme.spacing.S3};
`
);

const StyledInputLabel = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

// Reasonable debounce for scanning
const DEBOUNCE_TIME = 1000;

export const BarcodeInput: React.FC<BarcodeInputProps> = ({
  visible,
  disabled,
  barcode,
  setBarcode,
  handleUtilityScan,
}) => {
  const debouncedFn = useDebounceSubsequent(handleUtilityScan, DEBOUNCE_TIME);
  const scannerOnSubmit = disabled ? noop : debouncedFn;
  updateOnZebraScan(scannerOnSubmit);

  const prevDefaultOnSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (barcode) {
      await handleUtilityScan(barcode);
    }
  };

  return (
    <StyledCard visible={visible}>
      <StyledTitle displayAs="h3">Scan a barcode</StyledTitle>
      <StyledDescription>Scan or manually enter a barcode</StyledDescription>
      <form onSubmit={prevDefaultOnSubmit} noValidate>
        <Input
          label={<StyledInputLabel>Barcode</StyledInputLabel>}
          value={barcode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBarcode(e.target.value)}
          disabled={disabled}
        />
      </form>
    </StyledCard>
  );
};
