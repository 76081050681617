import { ReactElement } from "react";
import { atom } from "recoil";
import { SortationStateKey } from "./SortationStateKeys";

interface NavbarStateProps {
  title?: string;
  rightAction?: ReactElement | null;
}

export const navbarState = atom<NavbarStateProps>({
  key: SortationStateKey.NAVBAR,
  default: {},
});
