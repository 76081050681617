import React, { useMemo, useState } from "react";
import {
  ButtonIcon,
  DefaultTheme,
  IconV2,
  IconV2Props,
  Radio,
  RadioProps,
  ThemeProps,
  Title,
  TitleProps,
} from "@deliverr/ui";
import styled from "@emotion/styled";
import { useSortationFeature } from "sortation/base/SortationFeature";
import { UtilityTask, UtilityTaskList, UtilityTaskNames } from "../utilityTasks";

const StyledButton = styled.button<ThemeProps>(
  ({ theme }) => `
  background-color: ${theme.colors.NEUTRAL["00"]};
  display: flex;
  align-items: center;
  padding: 1.25rem ${theme.spacing.S5};
  box-shadow: 0px 0px 2px rgba(218, 225, 232, 0.5), 0px 2px 4px rgba(218, 225, 232, 0.5);
  border-radius: ${theme.border.radius.R5};
`
);

const StyledText = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
  font-size: ${theme.font.size.F3};
  padding-right: 1rem;
  white-space: nowrap;
`
);

const StyledTask = styled.span<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  font-size: ${theme.font.size.F3};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  flex-grow: 1;
`
);

const StyledIconV2 = styled(IconV2)<IconV2Props, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  margin-left: 0.625rem;
`
);

const RadioSelectOverlay = styled.div<ThemeProps>(
  ({ theme }) => `
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${theme.colors.NEUTRAL[40]};
  z-index: 10;
  display: flex;
  flex-direction: column;
`
);

const TopBar = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S2};
  display: flex;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  z-index: 1; // box-shadow won't show without setting the z-index
  background: ${theme.colors.NEUTRAL["00"]};
  justify-content: space-between;
`
);

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
`;

const SelectTitle = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S2}
`
);

const StyledRadioGroup = styled.fieldset<ThemeProps>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL["00"]};
    height: 100%;
    padding: ${theme.spacing.S2} 0;
  `
);

const StyledRadio = styled(Radio)<RadioProps, DefaultTheme>(
  ({ theme }) => `
    margin: ${theme.spacing.S4} 0;
`
);

interface TaskSelectProps {
  selectedTask: string;
  setSelectedTask: (value: string) => void;
}

export const TaskSelect: React.FC<TaskSelectProps> = ({ selectedTask, setSelectedTask }) => {
  const [shouldRenderSelect, setShouldRenderSelect] = useState<boolean>(false);
  const { isCustomsScanEnabled } = useSortationFeature();

  // Filter tasks based on feature flag
  const taskList = useMemo(() => {
    if (isCustomsScanEnabled) {
      return UtilityTaskList;
    }
    return UtilityTaskList.filter((task) => task.type !== UtilityTask.CUSTOMS_STATUS);
  }, [isCustomsScanEnabled]);

  const handleRadioChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelectedTask(e.target.value);
    setShouldRenderSelect(false);
  };

  return shouldRenderSelect ? (
    <RadioSelectOverlay>
      <TopBar>
        <MenuTitle>
          <ButtonIcon
            data-testid="back-button"
            iconType="arrow-left"
            aria-label="back"
            size="STANDARD"
            transparent
            onClick={() => setShouldRenderSelect(false)}
          />
          <SelectTitle as="h4" displayAs="h4">
            Task
          </SelectTitle>
        </MenuTitle>
      </TopBar>
      <StyledRadioGroup>
        {taskList.map((task) => (
          <StyledRadio
            key={task.type}
            value={task.type}
            label={task.name}
            checked={selectedTask === task.type}
            labelStyles="grid-gap: 1.4rem; margin: 0 1.5rem;"
            onChange={handleRadioChange}
          />
        ))}
      </StyledRadioGroup>
    </RadioSelectOverlay>
  ) : (
    <StyledButton onClick={() => setShouldRenderSelect(true)}>
      <StyledText>Select task</StyledText>
      <StyledTask data-testid="task-name">{UtilityTaskNames[selectedTask] ?? "Select"}</StyledTask>
      <StyledIconV2 type="angle-right" />
    </StyledButton>
  );
};
