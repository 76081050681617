import React from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { DefaultTheme, Title, Button, ThemeProps } from "@deliverr/ui";
import { useShippingLabelDownload } from "./useShippingLabelDownload";
import { TrackingInput } from "../TrackingInput";

const Container = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  margin: ${theme.spacing.S5};
`
);

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  margin-bottom: ${theme.spacing.S5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
`
);

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    border-radius: ${theme.border.radius.R4}
  `
);

export const ShippingLabelDownload = () => {
  const { formatMessage } = useIntl();
  const {
    trackingCode,
    setTrackingCode,
    errorMessage,
    handleRedownload,
    handleRedownloadState,
  } = useShippingLabelDownload();

  const handleDownloadBtnClick = async () => {
    await handleRedownload(trackingCode);
  };

  return (
    <Container>
      <Card>
        <Title displayAs="h2">
          {formatMessage({
            id: "sortation.shippingLabelDownload.title",
            defaultMessage: "Enter the package's tracking code",
          })}
        </Title>
        <TrackingInput
          onSubmit={handleRedownload}
          onChange={setTrackingCode}
          trackingCode={trackingCode}
          errorMessage={errorMessage}
          disabled={handleRedownloadState.loading}
        />
      </Card>
      <StyledButton
        block
        onClick={handleDownloadBtnClick}
        disabled={trackingCode.length === 0 || handleRedownloadState.loading}
      >
        {formatMessage({ id: "sortation.shippingLabelDownload.button", defaultMessage: "Download label" })}
      </StyledButton>
    </Container>
  );
};
