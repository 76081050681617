import { BillOfLadingError, SortationGaylordError } from "@deliverr/sortation-client";
import { createSuccessNotification, logError, logStart } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useCallback, useMemo, useState } from "react";
import { useAsyncFn, useMount, useUnmount } from "react-use";
import { useSetRecoilState } from "recoil";
import { navbarState } from "sortation/base/navbarState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { isGaylord, isValidShipwellBolId } from "sortation/utils/stringValidation";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { TextButton } from "../../components/TextButton";
import { loadToTrailerMessageMap, LoadToTrailerModal } from "./modals";
import { useQuery } from "sortation/hooks/useQuery";

export enum LoadToTrailerScanState {
  AWAITING_BOL_SCAN = "AWAITING_BOL_SCAN",
  AWAITING_TRAILER_INPUT = "AWAITING_TRAILER_INPUT",
  AWAITING_GAYLORD_SCAN = "AWAITING_GAYLORD_SCAN",
}

export const useLoadToTrailerScan = () => {
  // Global State
  const setNavbar = useSetRecoilState(navbarState);

  // Local State
  const [bolId, setBolId] = useState<string>("");
  const [trailerId, setTrailerId] = useState<string>("");
  const [gaylordId, setGaylordId] = useState<string>("");
  const [loadToTrailerScanState, setLoadToTrailerScanState] = useState<LoadToTrailerScanState>(
    LoadToTrailerScanState.AWAITING_BOL_SCAN
  );

  // Hooks
  const { addAutoCloseNotification, resetNotifications, successResponse, infoResponse, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();

  const sortationClient = useSortationClient();
  const query = useQuery();

  useMount(async () => {
    // BOL ID param used when redirected here from Dock Door scan
    const bolIdParam = query.get("bolId");
    if (bolIdParam) {
      await onBolIdSubmit(bolIdParam);
    }
  });

  useUnmount(() => {
    resetNotifications();
    setNavbar({});
  });

  const resetPage = useCallback(() => {
    resetNotifications();
    setBolId("");
    setTrailerId("");
    setGaylordId("");
    setLoadToTrailerScanState(LoadToTrailerScanState.AWAITING_BOL_SCAN);

    setNavbar({
      title: "Bol/Trailer Scan",
      rightAction: undefined,
    });
  }, [setNavbar, resetNotifications]);

  const handleError = useCallback(
    (err) => {
      const errCode = err.message;

      let modal: LoadToTrailerModal;

      switch (errCode) {
        case SortationGaylordError.NOT_FOUND:
          modal = LoadToTrailerModal.GAYLORD_NOT_FOUND;
          break;
        case SortationGaylordError.GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN:
          modal = LoadToTrailerModal.GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN;
          break;
        case LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT:
          modal = LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT;
          break;
        case LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT:
          modal = LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT;
          break;
        case LoadToTrailerModal.INVALID_BOL:
          modal = LoadToTrailerModal.INVALID_BOL;
          break;
        case LoadToTrailerModal.INVALID_BOL_ID:
          modal = LoadToTrailerModal.INVALID_BOL_ID;
          break;
        default:
          modal = LoadToTrailerModal.UNKNOWN_ERROR;
          break;
      }

      emitFlash("DANGER");
      showModal(modal, loadToTrailerMessageMap[modal]);
    },
    [emitFlash, showModal]
  );

  const [onGaylordIdSubmitState, onGaylordIdSubmit] = useAsyncFn(
    async (input: string) => {
      resetNotifications();

      if (!isGaylord(input)) {
        if (isValidShipwellBolId(input)) {
          return await onBolIdSubmit(input);
        }
        handleError(new Error(LoadToTrailerModal.INVALID_GAYLORD_ID));
        return;
      }

      setGaylordId(input);
      try {
        await sortationClient.processLoadToTrailerScan(input, bolId);
        successResponse();
        addAutoCloseNotification(createSuccessNotification(`${input} scanned to BOL & Trailer.`), TOAST_AUTO_CLOSE);
        setGaylordId("");
      } catch (err) {
        handleError(err);
        return;
      }
    },
    [addAutoCloseNotification, infoResponse, sortationClient, handleError, setGaylordId]
  );

  const [onBolIdSubmitState, onBolIdSubmit] = useAsyncFn(
    async (input: string) => {
      const ctx = logStart({ fn: "onBolIdSubmit", input });

      if (!input.length) {
        return;
      }
      resetNotifications();

      if (!isValidShipwellBolId(input)) {
        handleError(new Error(LoadToTrailerModal.INVALID_BOL_ID));
        return;
      }

      try {
        const existingBol = await sortationClient.getBillOfLading(input);
        if (!existingBol) {
          handleError(new Error(LoadToTrailerModal.INVALID_BOL_ID));
          return;
        }

        if (!existingBol.trailerId) {
          handleError(new Error(LoadToTrailerModal.INVALID_BOL));
          return;
        }

        setBolId(input);
        setTrailerId(existingBol.trailerId);
        setGaylordId("");
        setLoadToTrailerScanState(LoadToTrailerScanState.AWAITING_GAYLORD_SCAN);

        infoResponse();
        addAutoCloseNotification(
          createSuccessNotification(`BOL ${input} successfully scanned. Please continue scanning gaylords.`),
          TOAST_AUTO_CLOSE
        );
        setNavbar({
          title: "Bol/Trailer Scan",
          rightAction: TextButton({ onClick: resetPage, defaultMessage: "Reset BOL/Trailer" }),
        });
      } catch (err) {
        if (err.message === BillOfLadingError.BOL_NOT_FOUND) {
          setBolId(input);
          setTrailerId("");
          setGaylordId("");
          setLoadToTrailerScanState(LoadToTrailerScanState.AWAITING_TRAILER_INPUT);

          infoResponse();
          addAutoCloseNotification(
            createSuccessNotification(`BOL ${input} successfully scanned. Please enter Truck/Trailer ID.`),
            TOAST_AUTO_CLOSE
          );
          return;
        }
        logError(ctx, err);
        handleError(new Error(LoadToTrailerModal.UNKNOWN_ERROR));
      }
    },
    [bolId, setBolId, trailerId, setTrailerId, resetNotifications, handleError, setBolId, resetPage]
  );

  const [onTrailerIdSubmitState, onTrailerIdSubmit] = useAsyncFn(
    async (input: string) => {
      const ctx = logStart({ fn: "onTrailerIdSubmit", input });

      if (!input.length) {
        return;
      }

      if (isGaylord(input)) {
        handleError(new Error(LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT));
        return;
      }

      if (isValidShipwellBolId(input)) {
        handleError(new Error(LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT));
        return;
      }

      resetNotifications();

      try {
        await sortationClient.createOrUpdateBillOfLading(bolId, input);

        setTrailerId(input);
        setLoadToTrailerScanState(LoadToTrailerScanState.AWAITING_GAYLORD_SCAN);
        infoResponse();
        addAutoCloseNotification(
          createSuccessNotification(`BOL ${bolId} successfully saved to system. You may now scan gaylords to BOL.`),
          TOAST_AUTO_CLOSE
        );
        setNavbar({
          title: "Bol/Trailer Scan",
          rightAction: TextButton({ onClick: resetPage, defaultMessage: "Reset BOL/Trailer" }),
        });
      } catch (err) {
        logError(ctx, err);
        handleError(new Error(LoadToTrailerModal.UNKNOWN_ERROR));
      }
    },
    [trailerId, setTrailerId, trailerId, setTrailerId, resetNotifications, handleError, setBolId, resetPage, bolId]
  );

  const scanConfig = useMemo(() => {
    switch (loadToTrailerScanState) {
      case LoadToTrailerScanState.AWAITING_BOL_SCAN:
        return {
          title: "Scan BOL QR Code",
          label: "BOL ID",
          value: bolId,
          onSubmit: onBolIdSubmit,
          onChange: setBolId,
          disabled: onBolIdSubmitState.loading,
        };
      case LoadToTrailerScanState.AWAITING_TRAILER_INPUT:
        return {
          title: "Type in Truck/Trailer ID",
          label: "Truck/Trailer ID",
          value: trailerId,
          onSubmit: onTrailerIdSubmit,
          onChange: setTrailerId,
          disabled: onTrailerIdSubmitState.loading,
        };
      case LoadToTrailerScanState.AWAITING_GAYLORD_SCAN:
        return {
          title: "Scan Gaylord Barcode",
          label: "Gaylord ID",
          value: gaylordId,
          onSubmit: onGaylordIdSubmit,
          onChange: setGaylordId,
          disabled: onGaylordIdSubmitState.loading,
        };
    }
  }, [
    loadToTrailerScanState,
    bolId,
    onBolIdSubmit,
    setBolId,
    onBolIdSubmitState,
    trailerId,
    onTrailerIdSubmit,
    setTrailerId,
    onTrailerIdSubmitState,
    gaylordId,
    onGaylordIdSubmit,
    setGaylordId,
    onGaylordIdSubmitState,
  ]);

  return {
    bolId,
    trailerId,
    scanConfig,
    loadToTrailerScanState,
  };
};
