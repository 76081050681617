import React from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { DefaultTheme, Title, Button, ThemeProps } from "@deliverr/ui";
import { useShippingLabelPrint } from "./useShippingLabelPrint";
import { TrackingInput } from "../TrackingInput";

const Container = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  margin: ${theme.spacing.S5};

`
);

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  margin-bottom: ${theme.spacing.S5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
`
);

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    border-radius: ${theme.border.radius.R4}
  `
);

export const ShippingLabel = () => {
  const { formatMessage } = useIntl();
  const { trackingCode, setTrackingCode, errorMessage, handleReprint, handleReprintState } = useShippingLabelPrint();

  const handlePrintBtnClick = async () => {
    await handleReprint(trackingCode);
  };

  return (
    <Container>
      <Card>
        <Title displayAs="h2">
          {formatMessage({
            id: "sortation.shippingLabelPrint.title",
            defaultMessage: "Enter the package's tracking code",
          })}
        </Title>
        <TrackingInput
          onSubmit={handleReprint}
          onChange={setTrackingCode}
          trackingCode={trackingCode}
          errorMessage={errorMessage}
          disabled={handleReprintState.loading}
        />
      </Card>
      <StyledButton
        block
        onClick={handlePrintBtnClick}
        disabled={trackingCode.length === 0 || handleReprintState.loading}
      >
        {formatMessage({ id: "sortation.shippingLabelPrint.button", defaultMessage: "Print label" })}
      </StyledButton>
    </Container>
  );
};
