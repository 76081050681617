import { DefaultTheme, Stack, StyledButton, Text, Title, IconV2 } from "@deliverr/ui";
import { LinkButtonV2 } from "@deliverr/ui-facility";
import styled, { StyledOptions } from "@emotion/styled";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { SortationPath } from "sortation/base/sortationPath";

const Container = styled.section<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: grid;
  grid-template-rows: 1fr 1fr;
  background: linear-gradient(144.07deg, #000237 7.74%, #145B75 139.32%);
  padding: ${theme.spacing.S6} ${theme.spacing.S4};
  height: 100vh;
  color: ${theme.colors.NEUTRAL["00"]};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
);

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Image = styled.img`
  width: 200px;
  height: auto;
`;

const SortationText = styled(Text)`
  color: #fa5959;
`;

const BackButton = styled(StyledButton)<StyledOptions, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL["00"]};
  background-color: ${theme.colors.NEUTRAL[300]};
  border-color: ${theme.colors.NEUTRAL[300]};
`
);

const enum DashboardStateKey {
  DEFAULT = "DEFAULT",
  GAYLORD_SCAN_PACKAGES = "GAYLORD_SCAN_PACKAGES",
  GAYLORD_SCAN_OUTBOUND = "GAYLORD_SCAN_OUTBOUND",
}

export const Dashboard: React.FC = () => {
  const [currentState, setCurrentState] = useState<DashboardStateKey>(DashboardStateKey.DEFAULT);

  const handleButtonClick = (value: DashboardStateKey) => {
    setCurrentState(value);
  };

  return (
    <Container>
      <Stack direction="VERTICAL" gap="S2">
        <Image src="/images/logo-light.png" />
        <SortationText color="#FA5959" bold>
          <FormattedMessage id="sortation.dashboard.sortation" defaultMessage="Sortation Portal" />
        </SortationText>
      </Stack>
      <Body>
        <div>
          <Title as="h1" displayAs="h2">
            <FormattedMessage id="sortation.dashboard.title" defaultMessage="Welcome back" />
          </Title>
          <FormattedMessage id="sortation.dashboard.desc" defaultMessage="What are you working on today?" />
        </div>
        <Stack>
          {currentState === DashboardStateKey.DEFAULT && (
            <>
              <StyledButton onClick={() => handleButtonClick(DashboardStateKey.GAYLORD_SCAN_PACKAGES)}>
                <FormattedMessage id="sortation.dashboard.gaylordScanPackagesBtn" defaultMessage="Package Scan" />
              </StyledButton>
              <StyledButton onClick={() => handleButtonClick(DashboardStateKey.GAYLORD_SCAN_OUTBOUND)}>
                <FormattedMessage
                  id="sortation.dashboard.gaylordScanOutboundBtn"
                  defaultMessage="Gaylord Scan (Outbound)"
                />
              </StyledButton>
              <LinkButtonV2 to={SortationPath.LABEL_PRINT}>
                <FormattedMessage id="sortation.dashboard.printLabelsBtn" defaultMessage="Print labels" />
              </LinkButtonV2>
            </>
          )}

          {currentState === DashboardStateKey.GAYLORD_SCAN_PACKAGES && (
            <>
              <LinkButtonV2 to={SortationPath.ARRIVAL}>
                <FormattedMessage id="sortation.dashboard.arrivalBtn" defaultMessage="Arrival Scans" />
              </LinkButtonV2>
              <LinkButtonV2 to={SortationPath.OUTBOUND}>
                <FormattedMessage id="sortation.dashboard.outboundBtn" defaultMessage="Gaylord Scans" />
              </LinkButtonV2>
              <BackButton onClick={() => handleButtonClick(DashboardStateKey.DEFAULT)}>
                <IconV2 type={"arrow-left"} />
                <FormattedMessage id="sortation.dashboard.back" defaultMessage="Back" />
              </BackButton>
            </>
          )}

          {currentState === DashboardStateKey.GAYLORD_SCAN_OUTBOUND && (
            <>
              <LinkButtonV2 to={SortationPath.DOCK_DOOR_SCAN_V2}>
                <FormattedMessage id="sortation.dashboard.dockDoor" defaultMessage="Dock Door" />
              </LinkButtonV2>
              <LinkButtonV2 to={SortationPath.LEFT_IN_BUILDING_SCAN}>
                <FormattedMessage id="sortation.dashboard.leftInBuilding" defaultMessage="Left In Building" />
              </LinkButtonV2>
              <LinkButtonV2 to={SortationPath.LOAD_TO_TRAILER_SCAN}>
                <FormattedMessage id="sortation.dashboard.loadToTrailer" defaultMessage="BOL/Trailer Scan" />
              </LinkButtonV2>
              <BackButton onClick={() => handleButtonClick(DashboardStateKey.DEFAULT)}>
                <IconV2 type={"arrow-left"} />
                <FormattedMessage id="sortation.dashboard.back" defaultMessage="Back" />
              </BackButton>
            </>
          )}
        </Stack>
      </Body>
    </Container>
  );
};
