import { useCallback, useEffect } from "react";
import { TrafficType, initSplitClient } from "@deliverr/ui-facility";
import { useRecoilState, useRecoilValue } from "recoil";

import { sortationIdState } from "../sortationIdState";
import { sortationFeatureClientState } from "./sortationFeatureClientState";

export const useSortationFeatureClient = () => {
  const sortationId = useRecoilValue(sortationIdState);
  const [isClientInitialized, setIsClientInitialized] = useRecoilState(sortationFeatureClientState);

  const initializeSplitClient = useCallback(async () => {
    if (sortationId && !isClientInitialized) {
      await initSplitClient(sortationId, TrafficType.SORTATION);
      setIsClientInitialized(true);
    }
  }, [sortationId, isClientInitialized, setIsClientInitialized]);

  useEffect(() => {
    void initializeSplitClient();
  }, [initializeSplitClient, sortationId]);

  return { isClientInitialized };
};
