export enum SortationStateKey {
  SORTATION_ID = "SORTATION_ID",
  CLIENT_ID = "SORTATION_CLIENT_ID",
  USER = "SORTATION_USER",
  SORTATION_MODAL = "SORTATION_MODAL",
  VISIBLE_MODAL = "VISIBLE_MODAL",
  NAVBAR = "NAVBAR",
  DESKTOP_PRINTER = "DESKTOP_PRINTER",
  SORTATION_AUTH_DISABLED = "SORTATION_AUTH_DISABLED",
  SORTATION_FEATURE_CLIENT_STATE = "SORTATION_FEATURE_CLIENT_STATE",
}
