import { DefaultTheme, Title } from "@deliverr/ui";
import { CarrierNames } from "sortation/base/carrierNames";
import styled from "@emotion/styled";
import { isNil } from "lodash/fp";
import React from "react";
import { FormattedMessage } from "react-intl";

const Container = styled.div<{ hasCarrierOrSortCode: boolean; hasValue: boolean }, DefaultTheme>(
  ({ hasValue, hasCarrierOrSortCode, theme }) => `
  padding: ${theme.spacing.S6};
  color: ${hasValue ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL[300]};
  /* design todo: fix radius */
  border-radius: 8px 8px 0 0;
  background-color: ${
    hasCarrierOrSortCode ? theme.colors.BLUE[400] : hasValue ? theme.colors.NEUTRAL[400] : theme.colors.NEUTRAL[80]
  };
  position: relative;
`
);

// Unique title size
const Value = styled(Title)`
  font-size: 44px;
  line-height: 54px;
`;

const LargeText = styled.span`
  font-size: 64px;
  line-height: 84px;
`;

const BoldedText = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F5};
  line-height: 26px;
`
);

interface DivertLaneTitleProps {
  value?: string;
  carrier?: string;
  sortCode?: string;
}

export const DivertLaneTitle: React.FC<DivertLaneTitleProps> = ({ value, carrier, sortCode }) => {
  const carrierName = carrier && CarrierNames[carrier] ? <FormattedMessage {...CarrierNames[carrier]} /> : carrier;
  const hasCarrierOrSortCode = !isNil(carrier) || !isNil(sortCode);

  return (
    <Container hasValue={!!value} hasCarrierOrSortCode={hasCarrierOrSortCode} data-testid="divert_lane_container">
      {hasCarrierOrSortCode ? (
        <div>
          <LargeText>{sortCode ?? carrierName}</LargeText>
          {sortCode ? <p>{carrierName}</p> : null}
          <p>{value}</p>
        </div>
      ) : value ? (
        <div>
          <Value as="span">{value}</Value>
        </div>
      ) : (
        <>
          <BoldedText>
            <FormattedMessage id="sortation.scanDivertLane.noneChosen" defaultMessage="No divert lane chosen" />
          </BoldedText>
          <Value></Value>
        </>
      )}
    </Container>
  );
};
