import { SortationGaylord, SortationGaylordError } from "@deliverr/sortation-client";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useCallback, useState } from "react";
import { useAsyncFn } from "react-use";
import { useSortationClient } from "sortation/base/useSortationClient";
import { isGaylord } from "sortation/utils/stringValidation";
import { createSuccessNotification } from "@deliverr/ui-facility";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { useSortationModal } from "sortation/components/SortationModal";
import { LeftInBuildingScanModal, leftInBuildingScanMessageMap } from "./modals";
import { LeftInBuildingReasonCode } from "./LeftInBuildingReasonCode";

export const useLeftInBuildingScan = () => {
  // Local State
  const [textInputValue, setTextInputValue] = useState<string>("");
  const [gaylord, setGaylord] = useState<SortationGaylord>();
  const [reasonCode, setReasonCode] = useState<LeftInBuildingReasonCode>();
  const [reasonDetails, setReasonDetails] = useState<string>("");

  const handleReasonCodeChange = (option) => {
    setReasonCode(option.value);
    setReasonDetails("");
  };

  const handleReasonDetailsChange = (event) => {
    const val = event.target.value;
    setReasonDetails(val);
  };

  // Hooks
  const { addAutoCloseNotification, resetNotifications, infoResponse, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();

  const sortationClient = useSortationClient();

  const handleError = useCallback(
    (err) => {
      const errCode = err.message;

      let modal: LeftInBuildingScanModal;

      switch (errCode) {
        case SortationGaylordError.NOT_FOUND:
          modal = LeftInBuildingScanModal.GAYLORD_NOT_FOUND;
          break;
        case SortationGaylordError.INVALID_LIB_REASON:
          modal = LeftInBuildingScanModal.INVALID_LIB_REASON;
          break;
        case LeftInBuildingScanModal.INVALID_BARCODE:
          modal = LeftInBuildingScanModal.INVALID_BARCODE;
          break;
        case LeftInBuildingScanModal.REASON_CODE_MISSING:
          modal = LeftInBuildingScanModal.REASON_CODE_MISSING;
          break;
        case LeftInBuildingScanModal.REASON_DETAILS_MISSING:
          modal = LeftInBuildingScanModal.REASON_DETAILS_MISSING;
          break;
        case LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG:
          modal = LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG;
          break;
        default:
          modal = LeftInBuildingScanModal.UNKNOWN_ERROR;
          break;
      }

      emitFlash("DANGER");
      showModal(modal, leftInBuildingScanMessageMap[modal]);
    },
    [emitFlash, showModal]
  );

  // Callback hook to handle gaylord scans
  const handleGaylordScan = useCallback(
    async (value: string) => {
      try {
        const updatedGaylord = await sortationClient.processLeftInBuildingScan(
          value,
          reasonCode,
          reasonDetails.length === 0 ? undefined : reasonDetails
        );

        // Update state
        setGaylord(updatedGaylord);
        setTextInputValue("");

        // Success sound, flash, and notification
        infoResponse();
        addAutoCloseNotification(createSuccessNotification(`${value} scanned.`), TOAST_AUTO_CLOSE);
      } catch (err) {
        handleError(err);
      }
    },
    [addAutoCloseNotification, infoResponse, sortationClient, handleError, reasonCode, reasonDetails]
  );

  // Async hook to handle all submits and track loading state
  const [handleSubmitState, handleSubmit] = useAsyncFn(
    async (value: string) => {
      resetNotifications();
      setTextInputValue(value);

      // Barcode validation
      const isValueGaylord = isGaylord(value);
      if (!isValueGaylord) {
        handleError(new Error(LeftInBuildingScanModal.INVALID_BARCODE));
        return;
      }

      // Reason Code validation
      if (!reasonCode) {
        handleError(new Error(LeftInBuildingScanModal.REASON_CODE_MISSING));
        return;
      }

      // Reason Details validation
      if (reasonCode === LeftInBuildingReasonCode.OTHER) {
        if (reasonDetails.length === 0) {
          handleError(new Error(LeftInBuildingScanModal.REASON_DETAILS_MISSING));
          return;
        } else if (reasonDetails.length > 50) {
          handleError(new Error(LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG));
          return;
        }
      }

      await handleGaylordScan(value);
    },
    [handleGaylordScan]
  );

  return {
    gaylord,
    textInputValue,
    setTextInputValue,
    handleSubmit,
    handleSubmitState,
    reasonCode,
    handleReasonCodeChange,
    reasonDetails,
    handleReasonDetailsChange,
  };
};
