import React, { Suspense } from "react";
import { ToastContainer } from "@deliverr/ui";
import { CenterSpinner, LoadingSpinner } from "@deliverr/ui-facility";
import { Route, Switch } from "react-router-dom";
import { SortationPage } from "./SortationPage";
import { routes } from "./routes";
import { SortationPath } from "./sortationPath";
import { useSortationApp } from "./useSortationApp";

export const SortationApp: React.FC = () => {
  const { isAppReady } = useSortationApp();

  if (!isAppReady) {
    return <LoadingSpinner loading />;
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        style={{ margin: "0.5rem" }}
        toastStyle={{ maxWidth: "95vw", margin: "0.5rem 0" }}
      />
      <Switch>
        {routes.map(({ path, page, name, previousPage }, i) => (
          <Route path={path} exact={true} key={i}>
            {/* Need Suspense for async selectors not to break render */}
            <Suspense fallback={<CenterSpinner loading={true} />}>
              {/* Show spinner while performing on app load tasks like set warehouseId */}
              <CenterSpinner loading={!isAppReady}>
                {path === SortationPath.DASHBOARD ? (
                  page
                ) : (
                  <SortationPage name={name} previousPage={previousPage}>
                    {page}
                  </SortationPage>
                )}
              </CenterSpinner>
            </Suspense>
          </Route>
        ))}
      </Switch>
    </>
  );
};
