import { usePrintZpl } from "@deliverr/ui-facility";
import { useBrowserPrint } from "./BrowserPrint/useBrowserPrint";
import { isMobile } from "./isMobile";

/**
 * Wrapper around both the Zebra mobile printing hook and
 * the BrowserPrint desktop printing hook. This will check
 * if the user is on a mobile device and print accordingly
 */
export const useSortationPrint = () => {
  const { printZpl: mobilePrint } = usePrintZpl();
  const { print: desktopPrint } = useBrowserPrint();

  const print = async (zpl: string) => {
    if (isMobile) {
      await mobilePrint(zpl);
    } else {
      await desktopPrint(zpl);
    }
  };

  return {
    print,
  };
};
