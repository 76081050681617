import { DockDoor } from "@deliverr/sortation-client";
import { DefaultTheme, IconV2, IconV2Props } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { DockDoorOverlay } from "../DockDoorOverlay";
import { useDockDoorSelect } from "./useDockDoorSelect";

const SelectDockDoorButton = styled.button<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: ${theme.spacing.S8};
  color: ${theme.colors.NEUTRAL[500]};
  font-size: ${theme.font.size.F3};
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.NEUTRAL["60"]};
`
);

const SelectText = styled.span<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
`
);

const StyledIcon = styled(IconV2)<IconV2Props, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S2};
`
);

export interface DockDoorSelectProps {
  selectedDockDoor?: DockDoor;
  setSelectedDockDoor: (dockDoor: DockDoor) => void;
}

export const DockDoorSelect: React.FC<DockDoorSelectProps> = ({ selectedDockDoor, setSelectedDockDoor }) => {
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
  const { dockDoors, getDockDoors, isLoading } = useDockDoorSelect();

  const onShowOverlay = async () => {
    setShouldShowOverlay(true);
    if (!dockDoors.length) {
      await getDockDoors();
    }
  };

  const onClose = () => {
    setShouldShowOverlay(false);
  };

  const onSelect = (dockDoor: DockDoor) => {
    setSelectedDockDoor(dockDoor);
    setShouldShowOverlay(false);
  };

  return shouldShowOverlay ? (
    <DockDoorOverlay
      selectedDockDoor={selectedDockDoor}
      dockDoors={dockDoors}
      isLoading={isLoading}
      onClose={onClose}
      onSelect={onSelect}
    />
  ) : (
    <SelectDockDoorButton onClick={onShowOverlay}>
      <span>Dock door</span>
      <SelectText>
        {selectedDockDoor ? selectedDockDoor.dockDoorName : "Select"} <StyledIcon type="angle-right" />
      </SelectText>
    </SelectDockDoorButton>
  );
};
