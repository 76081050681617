export enum LoadToTrailerModal {
  INVALID_BOL_ID = "INVALID_BOL_ID",
  GAYLORD_NOT_FOUND = "GAYLORD_NOT_FOUND",
  GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN = "GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN",
  TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT = "TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT",
  TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT = "TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT",
  INVALID_GAYLORD_ID = "INVALID_GAYLORD_ID",
  INVALID_BOL = "INVALID_BOL",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}
