export interface Task {
  type: UtilityTask;
  name: string;
}

export enum UtilityTask {
  BOX_COMPLIANCE_AUDIT = "BOX_COMPLIANCE_AUDIT",
  CUSTOMS_STATUS = "CUSTOMS_STATUS",
  DAMAGED_PACKAGE = "DAMAGED_PACKAGE",
  LIQUIDATIONS = "LIQUIDATIONS",
  LIQUIDATIONS_CONSOLIDATIONS = "LIQUIDATIONS_CONSOLIDATIONS",
  MISSING_LABEL_PACKAGE = "MISSING_LABEL_PACKAGE",
  POLYBAG_COMPLIANCE_AUDIT = "POLYBAG_COMPLIANCE_AUDIT",
  RETURNS_PACKAGE = "RETURNS_PACKAGE",
  TRUCK_CLOSEOUT = "TRUCK_CLOSEOUT",
}

export const UtilityTaskNames: Record<UtilityTask, string> = {
  [UtilityTask.BOX_COMPLIANCE_AUDIT]: "Box Compliance Audit",
  [UtilityTask.CUSTOMS_STATUS]: "Section 321 Customs Status",
  [UtilityTask.DAMAGED_PACKAGE]: "Damaged Package",
  [UtilityTask.LIQUIDATIONS]: "Liquidations",
  [UtilityTask.LIQUIDATIONS_CONSOLIDATIONS]: "Liquidations (Consolidations)",
  [UtilityTask.MISSING_LABEL_PACKAGE]: "Missing Label Package",
  [UtilityTask.POLYBAG_COMPLIANCE_AUDIT]: "Polybag Compliance Audit",
  [UtilityTask.RETURNS_PACKAGE]: "Returns Package",
  [UtilityTask.TRUCK_CLOSEOUT]: "Truck Closeout",
};

export const UtilityTaskList: Task[] = [
  {
    type: UtilityTask.BOX_COMPLIANCE_AUDIT,
    name: UtilityTaskNames.BOX_COMPLIANCE_AUDIT,
  },
  {
    type: UtilityTask.CUSTOMS_STATUS,
    name: UtilityTaskNames.CUSTOMS_STATUS,
  },
  {
    type: UtilityTask.DAMAGED_PACKAGE,
    name: UtilityTaskNames.DAMAGED_PACKAGE,
  },
  {
    type: UtilityTask.LIQUIDATIONS,
    name: UtilityTaskNames.LIQUIDATIONS,
  },
  {
    type: UtilityTask.LIQUIDATIONS_CONSOLIDATIONS,
    name: UtilityTaskNames.LIQUIDATIONS_CONSOLIDATIONS,
  },
  {
    type: UtilityTask.MISSING_LABEL_PACKAGE,
    name: UtilityTaskNames.MISSING_LABEL_PACKAGE,
  },
  {
    type: UtilityTask.POLYBAG_COMPLIANCE_AUDIT,
    name: UtilityTaskNames.POLYBAG_COMPLIANCE_AUDIT,
  },

  {
    type: UtilityTask.RETURNS_PACKAGE,
    name: UtilityTaskNames.RETURNS_PACKAGE,
  },
  {
    type: UtilityTask.TRUCK_CLOSEOUT,
    name: UtilityTaskNames.TRUCK_CLOSEOUT,
  },
];
