import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { InitConfiguration } from "@datadog/browser-core";
import { logger } from "@deliverr/ui-logging";

declare const VERSION: string; // This value is set via webpack.config.js

export const getIsValidDataDogEnv = () => window.location.href.includes("sortationportal.flexport.com");

interface EventOverrides {
  view?: {
    url?: string;
    referrer?: string;
  };
}

export function initDataDog() {
  if (!getIsValidDataDogEnv()) {
    return;
  }

  const baseConfig: Pick<InitConfiguration, "clientToken" | "site" | "service" | "env" | "version"> = {
    clientToken: "pub387c5720d8faeda135e1b594db335105",
    site: "datadoghq.com",
    service: "sortation-portal",
    env: "production",
    version: `${VERSION}`,
  };

  // Initialize Browser Logger
  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  logger.info({ fn: "initDataDog" }, "Initializing DataDog!");

  // User monitoring / session replay
  datadogRum.init({
    ...baseConfig,
    applicationId: "a07a40b0-cb2b-45f1-8e5a-a66bd665d3c3",
    // do not sample (collect 100% of events)
    sampleRate: 100,
    // do not sample (collect 100% of session replays)
    replaySampleRate: 100,
    trackInteractions: true,
    // Masks all data in forms
    defaultPrivacyLevel: "allow",
    // This requires server-side changes to permit DataDog headers
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    // allowedTracingOrigins: [/https:\/\/fulfillment\.(prod|staging)\.deliverr\.com/],
    beforeSend: (event) => {
      // allow overriding event attributes, used for ensuring timing for actions with redirects reflect the initiating view
      const overrides = event.context?.overrides as EventOverrides;
      const viewOverrides = overrides?.view;
      if (viewOverrides) {
        Object.keys(viewOverrides).forEach((k) => {
          event.view[k] = viewOverrides[k];
        });
      }
    },
  });

  datadogRum.startSessionReplayRecording();
}

interface DataDogIdentityUpdate {
  user?: {
    id: string;
    warehouseId: string;
    userName: string;
    email: string;
    isManager: boolean;
    isAdmin: boolean;
    isHub: boolean;
  };
}

export function updateDataDogIdentity({ user }: DataDogIdentityUpdate) {
  if (!getIsValidDataDogEnv() || !user) {
    return;
  }
  const ctx = { fn: "updateDataDogIdentity", user: user?.email };
  logger.info(ctx, "Updating DataDog user");
  datadogRum.setUser({
    id: user.id,
    email: user.email,
    warehouseId: user.warehouseId,
    userName: user.userName,
    isManager: user.isManager,
    isAdmin: user.isAdmin,
    isHub: user.isHub,
  });
}
