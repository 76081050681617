import React from "react";
import { AssignmentFailedCodeModal } from "./AssignmentFailed";
import { DivertLaneModal } from "./DivertLaneModal";
import { EmptyDivertLaneCodeModal } from "./EmptyDivertLaneCode";
import { InvalidDivertLaneCodeModal } from "./InvalidDivertLaneCode";

export const divertLaneModalMap: Record<DivertLaneModal, React.FC<any>> = {
  [DivertLaneModal.INVALID_DIVERT_LANE_CODE]: InvalidDivertLaneCodeModal,
  [DivertLaneModal.EMPTY_DIVERT_LANE_CODE]: EmptyDivertLaneCodeModal,
  [DivertLaneModal.ASSIGNMENT_FAILED]: AssignmentFailedCodeModal,
};
