import React, { Suspense } from "react";
import { AppName, AuthProvider, CenterSpinner, EmotionCache, ProtectedRoute } from "@deliverr/ui-facility";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { SortationApp } from "./SortationApp";

export const SortationRoot: React.FC = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<CenterSpinner loading />}>
        <EmotionCache>
          <IntlProvider locale="en">
            <Router>
              {/* app is null for now. This prop can be removed once all facility apps are standalone */}
              <AuthProvider app={null}>
                <ProtectedRoute path={"/"} apps={[AppName.SC]} component={SortationApp} />
              </AuthProvider>
            </Router>
          </IntlProvider>
        </EmotionCache>
      </Suspense>
    </RecoilRoot>
  );
};

export default SortationRoot;
