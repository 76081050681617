import { useState } from "react";
import { createSuccessNotification, SoundFx } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useIntl } from "react-intl";
import { useAsyncFn, useUnmount } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navbarState } from "sortation/base/navbarState";
import { sortationIdState } from "sortation/base/sortationIdState";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationModal } from "sortation/components/SortationModal";
import { isDivertLane } from "sortation/utils/isDivertLane";
import { getDivertLaneId, getSorterId } from "sortation/utils/parseDivertLaneInput";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";
import { DivertLaneModal } from "./modals";
import { TextButton } from "sortation/components/TextButton";

export const useDivertLane = () => {
  const { formatMessage } = useIntl();
  const setNavbar = useSetRecoilState(navbarState);
  const [gaylord, setGaylord] = useState<string | undefined>(undefined);
  const [validDivertLane, setValidDivertLane] = useState<string | undefined>(undefined);
  const [divertLane, setDivertLane] = useState<string | undefined>(undefined);
  const { playSfx, addAutoCloseNotification, resetNotifications, emitFlash } = useCommonFlow();
  const { showModal } = useSortationModal();
  const sortationClient = useSortationClient();
  const sortationId = useRecoilValue(sortationIdState);

  useUnmount(() => {
    resetNotifications();
    setNavbar({});
  });

  const handleResetDivertLane = () => {
    setGaylord(undefined);
    setDivertLane(undefined);
    setValidDivertLane(undefined);
    setNavbar({
      title: formatMessage({ id: "sortation.name.scanGaylord", defaultMessage: "Scan Divert Lane" }),
      rightAction: undefined,
    });
  };

  const [handleDivertLaneChangeState, handleDivertLaneChange] = useAsyncFn(
    async (value: string) => {
      resetNotifications();
      if (!value) {
        emitFlash("DANGER");
        showModal(DivertLaneModal.EMPTY_DIVERT_LANE_CODE, {});
        return;
      }

      setDivertLane(value);
      if (isDivertLane(value)) {
        setValidDivertLane(value);
        playSfx(SoundFx.INFO);
        emitFlash("SUCCESS");
        setNavbar({
          title: formatMessage({ id: "sortation.name.loadGaylord", defaultMessage: "Load Gaylord" }),
          rightAction: TextButton({ onClick: handleResetDivertLane, defaultMessage: "change" }),
        });
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{code} scanned.`,
              },
              { code: value }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } else {
        emitFlash("DANGER");
        showModal(DivertLaneModal.INVALID_DIVERT_LANE_CODE, {});
      }
    },
    [sortationId]
  );

  const [handleGaylordCodeScanState, handleGaylordCodeScan] = useAsyncFn(
    async (input: string) => {
      resetNotifications();

      // When in the gaylord mapping flow, the user has the option to change the divert lane by scanning one.
      // if the scan is a divert lane, we'll have to switch over to handleDivertLaneChange fn.
      if (isDivertLane(input)) {
        await handleDivertLaneChange(input);
        setGaylord(undefined);
        return;
      }

      let sorterId: string | undefined;
      let divertLaneId: string | undefined;
      if (validDivertLane && sortationId) {
        sorterId = getSorterId(validDivertLane, sortationId);
        divertLaneId = getDivertLaneId(validDivertLane);
      }

      if (!sorterId || !divertLaneId) {
        emitFlash("DANGER");
        showModal(DivertLaneModal.INVALID_DIVERT_LANE_CODE, {});
        return;
      }

      setGaylord(input);
      try {
        await sortationClient.assignDivertLaneGaylord(sorterId, divertLaneId, input);
        handleResetDivertLane();
        playSfx(SoundFx.SUCCESS);
        emitFlash("SUCCESS");
        addAutoCloseNotification(
          createSuccessNotification(
            formatMessage(
              {
                id: "sortation.successScan",
                defaultMessage: `{input} scanned.`,
              },
              { input }
            )
          ),
          TOAST_AUTO_CLOSE
        );
      } catch (err) {
        emitFlash("DANGER");
        showModal(DivertLaneModal.ASSIGNMENT_FAILED, {});
      }
    },
    [validDivertLane]
  );

  const scanConfig = validDivertLane
    ? {
        title: formatMessage({
          id: "sortation.gaylordScan.title",
          defaultMessage: "Scan gaylord to assign to divert lane",
        }),
        label: formatMessage({ id: "sortation.gaylordScan.label", defaultMessage: "Gaylord code" }),
        value: gaylord,
        onSubmit: handleGaylordCodeScan,
        onChange: setGaylord,
        disabled: handleDivertLaneChangeState.loading,
      }
    : {
        title: formatMessage({ id: "sortation.divertLaneScan.title", defaultMessage: "Scan divert lane barcode" }),
        label: formatMessage({ id: "sortation.divertLaneScan.label", defaultMessage: "Divert lane code" }),
        value: divertLane,
        onSubmit: handleDivertLaneChange,
        onChange: setDivertLane,
        disabled: handleGaylordCodeScanState.loading,
      };

  return {
    validDivertLane,
    scanConfig,
  };
};
