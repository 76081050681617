import React from "react";
import { DefaultTheme, Title, TitleProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { LoadToTrailerScanState } from "../../useLoadToTrailerScan";

const Container = styled.div<{ hasValue: boolean }, DefaultTheme>(
  ({ hasValue, theme }) => `
  padding: ${theme.spacing.S6};
  color: ${hasValue ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL[300]};
  /* design todo: fix radius */
  border-radius: 8px 8px 0 0;
  background-color: ${hasValue ? theme.colors.BLUE[400] : theme.colors.NEUTRAL[80]};
  position: relative;
`
);

const LargeText = styled(Title)<TitleProps, DefaultTheme>(
  ({ theme }) => `
  font-size: ${theme.font.size.F5};
  line-height: 48px;
`
);

interface BolTitleProps {
  bolId?: string;
  trailerId?: string;
  loadToTrailerScanState: LoadToTrailerScanState;
}

export const BolTitle: React.FC<BolTitleProps> = ({ bolId, trailerId, loadToTrailerScanState }) => {
  return (
    <Container hasValue={loadToTrailerScanState === LoadToTrailerScanState.AWAITING_GAYLORD_SCAN}>
      <div>
        <LargeText>BOL: {loadToTrailerScanState !== LoadToTrailerScanState.AWAITING_BOL_SCAN && bolId}</LargeText>
        <p>Trailer: {loadToTrailerScanState === LoadToTrailerScanState.AWAITING_GAYLORD_SCAN && trailerId}</p>
      </div>
    </Container>
  );
};
