import React from "react";
import styled from "@emotion/styled";
import { Text } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

const Button = styled.button`
  appearance: none;
  margin: 0;
  padding: 0;
  :focus {
    outline: none;
  }
`;

interface TextButtonProps {
  onClick: () => void;
  defaultMessage: string;
}

export const TextButton: React.FC<TextButtonProps> = ({ onClick, defaultMessage }) => (
  <Button onClick={onClick}>
    <Text bold appearance="DEFAULT">
      <FormattedMessage id="text-button" defaultMessage={defaultMessage} />
    </Text>
  </Button>
);
