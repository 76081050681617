import { FC } from "react";
import { LeftInBuildingScanModal } from "./LeftInBuildingScanModal";
import { LeftInBuildingScanErrorModal, LeftInBuildingScanErrorModalProps } from "./LeftInBuildingScanErrorModal";

export const leftInBuildingScanModalMap: Record<LeftInBuildingScanModal, FC<any>> = {
  [LeftInBuildingScanModal.INVALID_BARCODE]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.GAYLORD_NOT_FOUND]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.INVALID_LIB_REASON]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.UNKNOWN_ERROR]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.REASON_CODE_MISSING]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.REASON_DETAILS_MISSING]: LeftInBuildingScanErrorModal,
  [LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG]: LeftInBuildingScanErrorModal,
};

export const leftInBuildingScanMessageMap: Record<LeftInBuildingScanModal, LeftInBuildingScanErrorModalProps> = {
  [LeftInBuildingScanModal.INVALID_BARCODE]: {
    title: "Invalid barcode",
    text: "This barcode is unrecognized. Scan again or try another barcode.",
    hideModalValue: LeftInBuildingScanModal.INVALID_BARCODE,
  },
  [LeftInBuildingScanModal.GAYLORD_NOT_FOUND]: {
    title: "Invalid gaylord",
    text: "The gaylord scanned is not valid. Try again or scan a different gaylord.",
    hideModalValue: LeftInBuildingScanModal.GAYLORD_NOT_FOUND,
  },
  [LeftInBuildingScanModal.INVALID_LIB_REASON]: {
    title: "Invalid LIB reason",
    text: "The reason code and/or details are invalid. Please verify.",
    hideModalValue: LeftInBuildingScanModal.INVALID_LIB_REASON,
  },
  [LeftInBuildingScanModal.UNKNOWN_ERROR]: {
    title: "Unknown error",
    text: "An unknown error occurred. Please contact a supervisor.",
    hideModalValue: LeftInBuildingScanModal.UNKNOWN_ERROR,
  },
  [LeftInBuildingScanModal.REASON_CODE_MISSING]: {
    title: "Reason code missing",
    text: "Please select a reason code.",
    hideModalValue: LeftInBuildingScanModal.REASON_CODE_MISSING,
  },
  [LeftInBuildingScanModal.REASON_DETAILS_MISSING]: {
    title: "Reason details missing",
    text: "Please enter reason details.",
    hideModalValue: LeftInBuildingScanModal.REASON_DETAILS_MISSING,
  },
  [LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG]: {
    title: "Reason details too long",
    text: "Max character length is 50 characters",
    hideModalValue: LeftInBuildingScanModal.REASON_DETAILS_TOO_LONG,
  },
};
