import { DockDoor, DockDoorError } from "@deliverr/sortation-client";
import {
  connectedPrinterState,
  createDangerNotification,
  createSuccessNotification,
  log,
  logError,
  logStart,
} from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { isNil } from "lodash/fp";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useAsyncFn } from "react-use";
import { useRecoilValue } from "recoil";
import { desktopPrinterState } from "sortation/base/BrowserPrint";
import { useSortationClient } from "sortation/base/useSortationClient";
import { useSortationPrint } from "sortation/base/useSortationPrint";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

export const useDockDoorLabel = () => {
  const [selectedDockDoor, setSelectedDockDoor] = useState<DockDoor>();
  const [amount, setAmount] = useState<number>(1);

  const { formatMessage } = useIntl();

  const sortationClient = useSortationClient();

  // Mobile Printing Recoil State
  const hasConnectedPrinter = useRecoilValue(connectedPrinterState);

  // Desktop Printing Recoil State
  const desktopPrinter = useRecoilValue(desktopPrinterState);

  const { addAutoCloseNotification } = useCommonFlow();

  const { print } = useSortationPrint();
  const incrementAmount = () => setAmount((num) => num + 1);
  const decrementAmount = () => setAmount((num) => num - 1);
  const isDecrementDisabled = amount === 1;

  const [printDockDoorLabelsState, printDockDoorLabels] = useAsyncFn(async () => {
    const ctx = logStart({ fn: "printDockDoorLabels", selectedDockDoor, amount });

    try {
      // We should never get here, but just in-case, we will create a toast
      if (!selectedDockDoor) {
        addAutoCloseNotification(
          createDangerNotification(
            formatMessage({
              id: "sortation.dockDoorPrint.printDockDoorLabels.error",
              defaultMessage: "No selected dock door, please try again.",
            })
          ),
          TOAST_AUTO_CLOSE
        );
        return;
      }

      const dockDoorLabel = await sortationClient.getDockDoorLabel(selectedDockDoor.dockDoorId);

      log(ctx, "Retrieved dock door label");

      for (let i = 0; i < amount; i++) {
        await print(dockDoorLabel.labelContents);
      }
      addAutoCloseNotification(createSuccessNotification("Label sent to printer"), TOAST_AUTO_CLOSE);
    } catch (err) {
      logError(ctx, err);

      // Dock door does not exist toast
      if (err.message === DockDoorError.DOCK_DOOR_DOES_NOT_EXIST) {
        addAutoCloseNotification(
          createDangerNotification("Selected dock door does not exist, please try again."),
          TOAST_AUTO_CLOSE
        );
        return;
      }

      // Generic toast
      addAutoCloseNotification(createDangerNotification("Something went wrong, please try again."), TOAST_AUTO_CLOSE);
    }
  }, [amount, selectedDockDoor]);

  return {
    selectedDockDoor,
    setSelectedDockDoor,
    amount,
    isDecrementDisabled,
    incrementAmount,
    decrementAmount,
    printDockDoorLabels,
    printDockDoorLabelsState,
    hasConnectedPrinter: !isNil(hasConnectedPrinter) || !isNil(desktopPrinter),
  };
};
