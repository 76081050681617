import { DockDoor } from "@deliverr/sortation-client";
import { ButtonIcon, DefaultTheme, Radio } from "@deliverr/ui";
import { LoadingSpinner } from "@deliverr/ui-facility";
import styled from "@emotion/styled";
import React from "react";

const DockDoorSelectOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f3f6f8;
  z-index: 10;
`;

const TopBar = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 64px;
  padding: ${theme.spacing.S2} ${theme.spacing.S3};
  background-color: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
`
);

const StyledButtonIcon = styled(ButtonIcon)`
  width: 48px;
  height: 48px;
  align-self: center;
`;

const SelectTitle = styled.h1<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin-left: ${theme.spacing.S2};
  font-family: ${theme.font.family.TITLE};
  font-size: ${theme.font.size.F4};
  font-weight: ${theme.font.weight.BOLD};
`
);

const DockDoorListContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  background-color: #fff;
  margin: ${theme.spacing.S5};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S1};
`
);

const StyledRadio = styled(Radio)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  margin: ${theme.spacing.S4};
`
);

export interface DockDoorOverlayProps {
  selectedDockDoor?: DockDoor;
  dockDoors: DockDoor[];
  isLoading: boolean;
  onClose: () => void;
  onSelect: (dockDoor: DockDoor) => void;
}

export const DockDoorOverlay: React.FC<DockDoorOverlayProps> = ({
  selectedDockDoor,
  dockDoors,
  isLoading,
  onClose,
  onSelect,
}) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Target is the dockDoorId
    const { target } = event;

    const dockDoor = dockDoors.find(({ dockDoorId }) => dockDoorId === target.value);
    if (dockDoor) {
      onSelect(dockDoor);
    }
  };

  return (
    <DockDoorSelectOverlay>
      <TopBar>
        <StyledButtonIcon iconType="arrow-left" aria-label="go back" transparent onClick={onClose} />
        <SelectTitle>Dock door</SelectTitle>
      </TopBar>
      <DockDoorListContainer>
        <LoadingSpinner loading={isLoading}>
          <fieldset>
            {dockDoors.map(({ dockDoorName, dockDoorId }) => (
              <StyledRadio
                key={dockDoorId}
                label={dockDoorName}
                value={dockDoorId}
                checked={selectedDockDoor?.dockDoorId === dockDoorId}
                onChange={onChangeHandler}
              />
            ))}
          </fieldset>
        </LoadingSpinner>
      </DockDoorListContainer>
    </DockDoorSelectOverlay>
  );
};
