import { FC } from "react";
import { LoadToTrailerModal } from "./LoadToTrailerModal";
import { LoadToTrailerErrorModal, LoadToTrailerErrorModalProps } from "./LoadToTrailerErrorModal";

export const loadToTrailerModalMap: Record<LoadToTrailerModal, FC<any>> = {
  [LoadToTrailerModal.INVALID_BOL_ID]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.GAYLORD_NOT_FOUND]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.INVALID_BOL]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.INVALID_GAYLORD_ID]: LoadToTrailerErrorModal,
  [LoadToTrailerModal.UNKNOWN_ERROR]: LoadToTrailerErrorModal,
};

export const loadToTrailerMessageMap: Record<LoadToTrailerModal, LoadToTrailerErrorModalProps> = {
  [LoadToTrailerModal.INVALID_BOL_ID]: {
    title: "Invalid BOL ID",
    text: "This BOL ID is unrecognized. Scan again or try another barcode.",
    hideModalValue: LoadToTrailerModal.INVALID_BOL_ID,
  },
  [LoadToTrailerModal.INVALID_BOL]: {
    title: "Invalid BOL",
    text: "Existing BOL in the system is invalid. Please contact a supervisor",
    hideModalValue: LoadToTrailerModal.INVALID_BOL_ID,
  },
  [LoadToTrailerModal.INVALID_GAYLORD_ID]: {
    title: "Invalid GAYLORD ID",
    text: "This Gaylord ID is unrecognized. Scan again or try another barcode.",
    hideModalValue: LoadToTrailerModal.INVALID_GAYLORD_ID,
  },
  [LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT]: {
    title: "Trailer ID value cannot be a Gaylord ID",
    text: "Value matches Gaylord ID format. Please input the truck/trailer ID.",
    hideModalValue: LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_GAYLORD_ID_FORMAT,
  },
  [LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT]: {
    title: "Trailer ID value cannot be a BOL ID",
    text: "Value matches BOL ID format. Please input the truck/trailer ID.",
    hideModalValue: LoadToTrailerModal.TRAILER_ID_INPUT_MATCHES_BOL_ID_FORMAT,
  },
  [LoadToTrailerModal.GAYLORD_NOT_FOUND]: {
    title: "Invalid gaylord",
    text: "The gaylord scanned is not valid. Try again or scan a different gaylord.",
    hideModalValue: LoadToTrailerModal.GAYLORD_NOT_FOUND,
  },
  [LoadToTrailerModal.GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN]: {
    title: "Gaylord status not allowed for trailer scan",
    text:
      "The gaylord scanned is in a state that is not permitted for BOL/Trailer scan. Try again or scan a different gaylord.",
    hideModalValue: LoadToTrailerModal.GAYLORD_STATUS_NOT_ALLOWED_FOR_TRAILER_SCAN,
  },
  [LoadToTrailerModal.UNKNOWN_ERROR]: {
    title: "Unknown error",
    text: "An unknown error occurred. Please try again. Contact a supervisor if the issue persists.",
    hideModalValue: LoadToTrailerModal.UNKNOWN_ERROR,
  },
};
