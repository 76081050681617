import { useState } from "react";
import { useRecoilState } from "recoil";
import { desktopPrinterState, useBrowserPrint } from "sortation/base/BrowserPrint";

export const useBrowserPrintSelect = () => {
  const [isSearchingPrinters, setIsSearchingPrinters] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [desktopPrinter, setDesktopPrinter] = useRecoilState(desktopPrinterState);

  const [printers, setPrinters] = useState<any[]>([]);

  const { getDefaultDevice, getLocalDevices, isPrinterReady } = useBrowserPrint();

  const initiatePrinterSearch = async () => {
    setIsSearchingPrinters(true);
    try {
      // Start with default printer
      const defaultPrinter = await getDefaultDevice();

      // Check connection of the default printer, if it's ready to print, set it as the selected printer
      const isPrinterReadyToPrint = await isPrinterReady(defaultPrinter);
      if (isPrinterReadyToPrint) {
        setDesktopPrinter(defaultPrinter);
        setPrinters([defaultPrinter]);
        setIsSearchingPrinters(false);
      }

      // Check for additional printers
      const localPrinters = await getLocalDevices();
      setPrinters((currentPrinters) => [...currentPrinters, ...localPrinters]);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsSearchingPrinters(false);
    }
  };

  return {
    desktopPrinter,
    error,
    isSearchingPrinters,
    printers,
    initiatePrinterSearch,
    setDesktopPrinter,
  };
};
