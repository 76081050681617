export enum SortationPath {
  // Nav Routes
  DASHBOARD = "/",
  HUB_APP_REDIRECT = "/hub_app_redirect",

  // Scan Routes
  ARRIVAL = "/arrival",
  OUTBOUND = "/outbound",
  DIVERT_LANE = "/divert_lane",
  DOCK_DOOR_SCAN_V2 = "/dock_door_scan_v2",
  UTILITY_SCAN = "/utility_scan",
  LEFT_IN_BUILDING_SCAN = "/left_in_building_scan",
  LOAD_TO_TRAILER_SCAN = "/load_to_trailer_scan",

  // Label Routes
  LABEL_PRINT = "/label_print",
  ZEBRA_LABEL_PRINT = "/zebra_label_print",
  SHIPPING_LABEL_DOWNLOAD = "/download_shipping_label",
  SHIPPING_LABEL = "/print_shipping_label",
  GAYLORD_LABEL = "/print_gaylord_label",
  DOCK_DOOR_LABEL = "/print_dock_door_label",
}
