import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { UtilityScanModal } from "./UtilityScanModal";

export const TrackingCodeNotFoundModal: React.FC = () => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={
        <FormattedMessage
          id="sortation.utilityScan.trackingCodeNotFoundModal.title"
          defaultMessage="Tracking Code Not Found"
        />
      }
      text={
        <FormattedMessage
          id="sortation.utilityScan.trackingCodeNotFoundModal.body"
          defaultMessage={
            "Tracking code not found for the scanned barcode. Please scan the correct barcode or contact a supervisor for assistance."
          }
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(UtilityScanModal.TRACKING_CODE_NOT_FOUND)}>
        <FormattedMessage id="sortation.utilityScan.trackingCodeNotFoundModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
