import React from "react";
import { ContinueButton, ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { LeftInBuildingScanModal } from "./LeftInBuildingScanModal";

export interface LeftInBuildingScanErrorModalProps {
  title: string;
  text: string;
  hideModalValue: LeftInBuildingScanModal;
}

export const LeftInBuildingScanErrorModal: React.FC<LeftInBuildingScanErrorModalProps> = ({
  title,
  text,
  hideModalValue,
}) => {
  const { hideModal } = useSortationModal();
  return (
    <ModalTemplate
      title={<FormattedMessage id="sortation.leftInBuildingScan.errorModalTitle" defaultMessage={title} />}
      text={<FormattedMessage id="sortation.leftInBuildingScan.errorModalText" defaultMessage={text} />}
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <ContinueButton block onClick={() => hideModal(hideModalValue)} />
    </ModalTemplate>
  );
};
