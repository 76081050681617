import { SoundFx, createDangerNotification } from "@deliverr/ui-facility";
import { useCommonFlow } from "@deliverr/ui-facility/lib-facility/flow/useCommonFlow";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TOAST_AUTO_CLOSE } from "sortation/utils/toastAutoClose";

export const useSortationCenterError = () => {
  const { formatMessage } = useIntl();
  const { addAutoCloseNotification, customResponse } = useCommonFlow();

  const errorResponse = useMemo(() => customResponse(SoundFx.ERROR_FAST, "DANGER"), [customResponse]);

  const handleSortationCenterError = () => {
    errorResponse();
    addAutoCloseNotification(
      createDangerNotification(
        formatMessage({
          id: "sortation.sortationid.error",
          defaultMessage: "We couldn't find your sortation id, please restart the app.",
        })
      ),
      TOAST_AUTO_CLOSE
    );
  };

  return {
    handleSortationCenterError,
  };
};
