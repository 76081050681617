import { FC } from "react";
import { DockDoorScanV2Modal } from "./DockDoorScanV2Modal";
import { DockDoorScanV2ErrorModal, DockDoorScanV2ErrorModalProps } from "./DockDoorScanV2ErrorModal";

export const dockDoorScanV2ModalMap: Record<DockDoorScanV2Modal, FC<any>> = {
  [DockDoorScanV2Modal.INVALID_BARCODE]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.GAYLORD_NOT_FOUND]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.MISSING_GAYLORD_SORT_CODE]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.GAYLORD_DOCK_DOOR_MISMATCH]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.DOCK_DOOR_MAPPING_NOT_FOUND]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.DOCK_DOOR_DOES_NOT_EXIST]: DockDoorScanV2ErrorModal,
  [DockDoorScanV2Modal.UNKNOWN_ERROR]: DockDoorScanV2ErrorModal,
};

export const dockDoorScanV2MessageMap: Record<DockDoorScanV2Modal, DockDoorScanV2ErrorModalProps> = {
  [DockDoorScanV2Modal.INVALID_BARCODE]: {
    title: "Invalid barcode",
    text: "This barcode is unrecognized. Scan again or try another barcode.",
    hideModalValue: DockDoorScanV2Modal.INVALID_BARCODE,
  },
  [DockDoorScanV2Modal.GAYLORD_NOT_FOUND]: {
    title: "Invalid gaylord",
    text: "The gaylord scanned is not valid. Try again or scan a different gaylord.",
    hideModalValue: DockDoorScanV2Modal.GAYLORD_NOT_FOUND,
  },
  [DockDoorScanV2Modal.MISSING_GAYLORD_SORT_CODE]: {
    title: "Gaylord missing sort code",
    text: "The gaylord scanned is empty or missing a sort code. Try again or scan a different gaylord.",
    hideModalValue: DockDoorScanV2Modal.MISSING_GAYLORD_SORT_CODE,
  },
  [DockDoorScanV2Modal.GAYLORD_DOCK_DOOR_MISMATCH]: {
    title: "Incorrect assignment",
    text: "The gaylord does not belong to the scanned dock door. Try again or scan a different dock door.",
    hideModalValue: DockDoorScanV2Modal.GAYLORD_DOCK_DOOR_MISMATCH,
  },
  [DockDoorScanV2Modal.DOCK_DOOR_MAPPING_NOT_FOUND]: {
    title: "Unknown dock door",
    text: "No dock door found for the scanned gaylord. Please contact a supervisor",
    hideModalValue: DockDoorScanV2Modal.DOCK_DOOR_MAPPING_NOT_FOUND,
  },
  [DockDoorScanV2Modal.DOCK_DOOR_DOES_NOT_EXIST]: {
    title: "Invalid dock door",
    text: "The dock door scanned is not valid. Try again or scan a different barcode.",
    hideModalValue: DockDoorScanV2Modal.DOCK_DOOR_DOES_NOT_EXIST,
  },
  [DockDoorScanV2Modal.UNKNOWN_ERROR]: {
    title: "Unknown error",
    text: "An unknown error occurred. Please contact a supervisor.",
    hideModalValue: DockDoorScanV2Modal.UNKNOWN_ERROR,
  },
};
