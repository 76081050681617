import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, SoundFx } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { useSortationModal } from "sortation/components/SortationModal";
import { sortationDangerIconProps } from "sortation/components/SortationModal/sortationModalIconProps";
import { UtilityScanModal } from "./UtilityScanModal";

export const UnknownCustomsStatusModal: React.FC = () => {
  const { hideModal } = useSortationModal();

  return (
    <ModalTemplate
      title={
        <FormattedMessage
          id="sortation.utilityScan.unknownCustomsStatusModal.title"
          defaultMessage="Unknown Customs Status"
        />
      }
      text={
        <FormattedMessage
          id="sortation.utilityScan.unknownCustomsStatusModal.body"
          defaultMessage={
            "Unable to determine the custom status for the scanned package. Please contact a supervisor for assistance."
          }
        />
      }
      iconProps={sortationDangerIconProps}
      soundFx={SoundFx.ERROR_FAST}
    >
      <Button block onClick={() => hideModal(UtilityScanModal.UNKNOWN_CUSTOMS_STATUS)}>
        <FormattedMessage id="sortation.utilityScan.unknownCustomsStatusModal.continue" defaultMessage="Continue" />
      </Button>
    </ModalTemplate>
  );
};
