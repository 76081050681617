import { Button, ButtonIcon, DefaultTheme, Stack, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useGaylordPrint } from "sortation/pages/LabelPrint/GaylordLabel/useGaylordPrint";

const Spread = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Card = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  background: ${theme.colors.NEUTRAL["00"]};
  border-radius: ${theme.border.radius.R5};
  box-shadow: ${theme.shadow.S2};
  margin-bottom: ${theme.spacing.S5};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.S4};
`
);

export const GaylordLabel = () => {
  const {
    amount,
    isDecrementDisabled,
    isIncrementDisabled,
    incrementAmount,
    decrementAmount,
    createGaylords,
    createGaylordState,
    hasConnectedPrinter,
  } = useGaylordPrint();

  return (
    <>
      <Card>
        <Spread>
          <Text bold>
            <FormattedMessage id="sortation.gaylordPrint.numberOfLabels" defaultMessage="Number of Labels" />
          </Text>
          <Stack direction="HORIZONTAL">
            <ButtonIcon
              secondary
              disabled={isDecrementDisabled}
              onClick={decrementAmount}
              iconType="minus"
              aria-label="decrement label"
              data-testid="gaylord_decrement"
            />
            <span data-testid="gaylord_amount">{amount}</span>
            <ButtonIcon
              secondary
              disabled={isIncrementDisabled}
              onClick={incrementAmount}
              iconType="plus"
              aria-label="increment label"
              data-testid="gaylord_increment"
            />
          </Stack>
        </Spread>
      </Card>
      <Button
        disabled={createGaylordState.loading || !hasConnectedPrinter || amount === 0}
        block
        onClick={createGaylords}
        data-testid="gaylord_print"
      >
        <FormattedMessage id="sortation.printLabel.gaylord.printButton" defaultMessage="Print label(s)" />
      </Button>
    </>
  );
};
