import { defineMessages } from "react-intl";
export const CarrierNames = defineMessages({
  usps: {
    id: "facilityCommons.carrier.usps",
    defaultMessage: "USPS",
  },
  axlehire: {
    id: "facilityCommons.carrier.axlehire",
    defaultMessage: "AxleHire",
  },
  bettertrucks: {
    id: "facilityCommons.carrier.bettertrucks",
    defaultMessage: "BetterTrucks",
  },
  dhl: {
    id: "facilityCommons.carrier.dhl",
    defaultMessage: "DHL",
  },
  cdl: {
    id: "facilityCommons.carrier.cdl",
    defaultMessage: "CDL",
  },
  dhl_ecommerce: {
    id: "facilityCommons.carrier.dhl_ecommerce",
    defaultMessage: "DHL Ecommerce",
  },
  dhl_express: {
    id: "facilityCommons.carrier.dhl_express",
    defaultMessage: "DHL Express",
  },
  doordash: {
    id: "facilityCommons.carrier.doordash",
    defaultMessage: "DoorDash",
  },
  fedex: {
    id: "facilityCommons.carrier.fedex",
    defaultMessage: "FedEx",
  },
  fedex_express: {
    id: "facilityCommons.carrier.fedex_express",
    defaultMessage: "Fedex Express",
  },
  gls: {
    id: "facilityCommons.carrier.gls",
    defaultMessage: "GLS",
  },
  lasership: {
    id: "facilityCommons.carrier.lasership",
    defaultMessage: "LaserShip",
  },
  lso: {
    id: "facilityCommons.carrier.lso",
    defaultMessage: "LSO",
  },
  ontrac: {
    id: "facilityCommons.carrier.ontrac",
    defaultMessage: "onTrac",
  },
  pcf: {
    id: "facilityCommons.carrier.pcf",
    defaultMessage: "PCF",
  },
  purolator: {
    id: "facilityCommons.carrier.purolator",
    defaultMessage: "Purolator",
  },
  tforce: {
    id: "facilityCommons.carrier.tforce",
    defaultMessage: "T-Force",
  },
  uds: {
    id: "facilityCommons.carrier.uds",
    defaultMessage: "UDS",
  },
  ups: {
    id: "facilityCommons.carrier.ups",
    defaultMessage: "UPS",
  },
  freight: {
    id: "facilityCommons.carrier.freight",
    defaultMessage: "Freight",
  },
});
